import Vue, { PropType } from 'vue';
import { getFileIcon, getImage, isMobileCheck, mdToHtml } from '@/util';
import { format } from 'date-fns';
import { Timestamp } from 'firebase/firestore';
import { getProduct, getProducts, getPromotion, getPromotions } from '@/resourceUtils';
import { getParameterByName, storeEventBus } from './store';

type Resource = BlogPost | Product | Promotion;

export const resourceCollectionMixin = Vue.extend({
  props: {
    items: {
      type: Array as PropType<Resource[]>,
      default: () => [],
    },
    noFilter: {
      type: Boolean,
      default: false,
    },
    initialMode: {
      type: String,
      default: 'card',
    },
  },
  data(): {
    page: number;
    pageCount: number;
    itemsPerPage: number;
    search: string;
    selectedTags: string[];
    isMobileCheck: boolean;
    tags: TextValue[];
    viewMode: string;
    showItemDialog: boolean;
    currItem: any;
    mode: string;
    carousel: number;
  } {
    const tags: TextValue[] = [
      // Products
      { text: 'Companion Animal', value: 'companionAnimal' },
      { text: 'Large Animal', value: 'largeAnimal' },
      { text: 'Parasitology', value: 'parasitology' },
      { text: 'Toxicology', value: 'toxicology' },
      { text: 'Dermatology', value: 'dermatology' },
      { text: 'Joint Support', value: 'jointSupport' },
      { text: 'Pain Management', value: 'painManagement' },
      { text: 'Behavior', value: 'behavior' },
      { text: 'Renal Support', value: 'renalSupport' },
      { text: 'Gastrointestinal', value: 'gastrointestinal' },
      { text: 'Dental Care', value: 'dentalCare' },
      { text: 'Immune Support', value: 'immuneSupport' },
      // Blog Posts
      { text: 'Product', value: 'product' },
      { text: 'Financial', value: 'financial' },
      { text: 'Award', value: 'award' },
      { text: 'Misc', value: 'misc' },
      //Promotions
      { text: 'Limited Time', value: 'limitedTime' },
      { text: 'Exclusive', value: 'exclusive' },
    ].filter((tag) => {
      return this.items.some((item) => {
        return item.tags?.includes(tag.value);
      });
    });

    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 9,
      search: '',
      selectedTags: [],
      isMobileCheck: isMobileCheck(),
      tags,
      viewMode: this.initialMode,
      showItemDialog: false,
      currItem: null,
      mode: this.initialMode,
      carousel: 0,
    };
  },
  computed: {    
    downloadUrlMap(): DownloadUrlMap {
      return this.$store.state.downloadUrlMap;
    },
    downloadMetadataMap(): DownloadMetadataMap {
      return this.$store.state.downloadMetadataMap;
    },
  },
  watch: {
    items() {
      this.showDialogForIdInURl();
    },
    showItemDialog(val) {
      if (!val) {
        this.$router.replace(this.$route.path);
      }
    },
  },
  mounted() {
    getProducts(); // A list of all products is needed on all resource pages for the related products section so just load them up on init.
    getPromotions(); // Promotions are used on both the product and promotions pages so load these up right away as well. They're fairly light objects anyhow.
    this.showDialogForIdInURl();
    storeEventBus.$on('resourcesUpdated', this.showDialogForIdInURl);
  },
  methods: {
    vimeoId(url: string): string {
      const regExp = /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/;
      const match = url.match(regExp);
      if (match) {
        return match[5];
      }
      return '';
    },
    showDialogForIdInURl() {
      if (this.$store.state.initialId || getParameterByName('id')) {
        this.showItemDialogFunc(this.$store.state.initialId || getParameterByName('id'));
      }
    },
    getImage(path: string, size: 'small' | 'large') {
      return getImage(path, size);
    },
    getFileIcon(fileName: string) {
      return getFileIcon(fileName);
    },
    getFileName(filePath: string) {
      const parts = filePath.split('/');
      return parts[parts.length - 1];
    },
    formatTimestamp(date: Timestamp) {
      return format(date.toDate(), 'MMM dd, yyyy');
    },
    mdToHtml(md: string, removeHtml = false) {
      return mdToHtml(md, removeHtml);
    },
    getProduct(productId: string) {
      return getProduct(productId);
    },
    getPromotion(promoId: string) {
      return getPromotion(promoId);
    },
    getItem(id: string): Resource | undefined {
      return this.items.find((p) => p.docId === id);
    },
    showProductDialogFunc(itemId: string) {
      const item = this.getProduct(itemId);
      this.openDialog(item, itemId, 'Products');
    },
    showPromotionDialogFunc(itemId: string) {
      const item = this.getPromotion(itemId);
      this.openDialog(item, itemId, 'Promotions');
    },
    showItemDialogFunc(itemId: string) {
      const item = this.getItem(itemId);
      this.openDialog(item, itemId);
    },
    openDialog(item: Resource | undefined, itemId: string, path = '') {
      if (!item) {
        return;
      } else {
        this.$store.commit('initialId', '');
      }
      this.currItem = item;
      this.showItemDialog = true;
      this.$router.replace(`${path || this.$route.path}?id=${itemId}`);
      Vue.nextTick(() => {
        const scrollArea = this.$refs.dialogScrollArea as HTMLElement;
        if (scrollArea) {
          scrollArea.scrollTop = 0;
        }
      });
    },
  },
});
