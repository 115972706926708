









import { firebaseCredentials } from '@/util';
import Vue from 'vue';
import VtqClinicalSolutions from '@/components/VtqClinicalSolutions.vue';
import { Loader } from '@googlemaps/js-api-loader';
import { getClinicalSolutions } from '@/resourceUtils';

export default Vue.extend({
  components: {
    VtqClinicalSolutions,
  },
  computed: {
    signInComplete(): boolean {
      return this.$store.state.signInComplete;
    },
    clinicalSolutions(): ClinicalSolution[] {
      return this.$store.state.clinicalSolutions;
    },
  },
  watch: {
    async signInComplete(val) {
      if (val) {
        await getClinicalSolutions();
      }
    },
  },
  async mounted() {
    await getClinicalSolutions();
    const loader = new Loader({
      apiKey: firebaseCredentials.apiKey,
      version: 'weekly',
    });
    loader.load();
  },
});
