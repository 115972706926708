import { Timestamp } from 'firebase/firestore';
import storage from 'local-storage-fallback';
import Vue from 'vue';
import Vuex from 'vuex';
import { eThemes, eUserRoles } from './enums';
import router from './router';

export const localStoreSet = (fieldName: string, storageString: string) => {
  storage.setItem(fieldName, storageString);
};

export const localStoreGet = (fieldName: string) => {
  return storage.getItem(fieldName);
};

export const storeEventBus = new Vue();

Vue.use(Vuex);

export const getParameterByName = (n: string): string => {
  const url: string = window.location.href;
  const name = n.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  const results = regex.exec(url);
  if (!results) {
    return '';
  }
  if (!results[2]) {
    return '';
  }
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

const entryPath = window.location.pathname;
const initialId = getParameterByName('id');

export const getEmptyUser = (): PlatformUser => {
  return {
    docId: '',
    role: eUserRoles.applicant,
    email: '',
    displayName: '',
    companyName: '',
    phone: '',
    webPushTokens: [],
    marketingEmailNotificationsEnabled: true,
    marketingSmsNotificationsEnabled: true,
    importantEmailNotificationsEnabled: true,
    importantSmsNotificationsEnabled: true,
    adminSelectedOrganizationEntities: [],
    adminSelectedOrganizations: [],
    userSelectedOrganizations: [],
    appTheme: eThemes.light,
    dateCreated: Timestamp.fromMillis(Date.now()),
    dateUpdated: Timestamp.fromMillis(Date.now()),
  };
};

export interface GlobalState {
  currUser: PlatformUser;
  isLoggedIn: boolean;
  isLoading: boolean;
  initialLoadIsHappening: boolean;
  downloadUrlMap: DownloadUrlMap;
  downloadMetadataMap: DownloadMetadataMap;
  appTheme: eThemes;
  territoryManagers: TerritoryManager[];
  blogPosts: BlogPost[];
  products: Product[];
  promotions: Promotion[];
  continuingEducation: ContinuingEducation[];
  clinicalSolutions: ClinicalSolution[];
  literature: Literature[];
  samples: Sample[];
  organizations: Organization[];
  initialId: string;
  contactPageContent: string;
  aboutPageContent: string;
  faqPageContent: { question: string; answer: string }[];
  signInComplete: boolean;
  dataForSignUp: {
    displayName: string;
    companyName: string;
    phone: string;
  };
}

function initialState() {
  const state: GlobalState = {
    currUser: getEmptyUser(),
    isLoggedIn: false,
    isLoading: false,
    initialLoadIsHappening: true,
    downloadUrlMap: {},
    downloadMetadataMap: {},
    appTheme: eThemes.light,
    territoryManagers: [],
    blogPosts: [],
    products: [],
    promotions: [],
    literature: [],
    samples: [],
    continuingEducation: [],
    clinicalSolutions: [],
    organizations: [],
    initialId,
    contactPageContent: '',
    aboutPageContent: '',
    faqPageContent: [],
    signInComplete: false,
    dataForSignUp: {
      displayName: '',
      companyName: '',
      phone: '',
    },
  };
  return state;
}

const routerDupeErrorCatch = (error: any) => {
  if (error.name !== 'NavigationDuplicated' && !error.message.includes('Avoided redundant navigation to current location')) {
    console.error(error);
  }
};

export const store = new Vuex.Store({
  state: initialState(),
  mutations: {
    reset(state) {
      // acquire initial state
      const s: GlobalState = initialState();
      Object.keys(s).forEach((key: string) => {
        (state as any)[key] = (s as any)[key];
      });
      state.initialLoadIsHappening = false;
    },
    isLoggedIn(state, payload: boolean) {
      state.isLoggedIn = payload;
      state.initialLoadIsHappening = false;
      if (payload === true) {
        // Logging in in this case so go to the original path.
        if (entryPath !== '/Login') {
          router.push(entryPath).catch(routerDupeErrorCatch);
        } else {
          router.push('/').catch(routerDupeErrorCatch);
        }
        // Not logged in so just go to login page.
      } else {
        router.push('/Login').catch(routerDupeErrorCatch);
      }
    },
    currUser(state, payload: PlatformUser) {
      const newUserData = {
        ...getEmptyUser(),
        ...payload,
      };
      state.currUser = newUserData;
      state.appTheme = newUserData.appTheme;
    },
    contactPageContent(state, payload: string) {
      state.contactPageContent = payload;
    },
    aboutPageContent(state, payload: string) {
      state.aboutPageContent = payload;
    },
    faqPageContent(state, payload: { question: string; answer: string }[]) {
      state.faqPageContent = payload;
    },
    signInComplete(state, payload: boolean) {
      state.signInComplete = payload;
    },
    isLoading(state, payload: boolean) {
      state.isLoading = payload;
    },
    appTheme(state, payload: eThemes) {
      state.appTheme = payload;
    },
    downloadUrlMap(state, payload: DownloadUrlMap) {
      state.downloadUrlMap = {
        ...state.downloadUrlMap,
        ...payload,
      };
    },
    downloadMetadataMap(state, payload: DownloadMetadataMap) {
      state.downloadMetadataMap = {
        ...state.downloadMetadataMap,
        ...payload,
      };
    },
    territoryManagers(state, payload: TerritoryManager[]) {
      state.territoryManagers = payload;
    },
    blogPosts(state, payload: BlogPost[]) {
      state.blogPosts = payload;
    },
    products(state, payload: Product[]) {
      state.products = payload;
    },
    promotions(state, payload: Promotion[]) {
      state.promotions = payload;
    },
    literature(state, payload: Literature[]) {
      state.literature = payload;
    },
    samples(state, payload: Sample[]) {
      state.samples = payload;
    },    
    continuingEducation(state, payload: ContinuingEducation[]) {
      state.continuingEducation = payload;
    },
    clinicalSolutions(state, payload: ClinicalSolution[]) {
      state.clinicalSolutions = payload;
    },
    organizations(state, payload: Organization[]) {
      state.organizations = payload;
    },
    initialId(state, payload: string) {
      state.initialId = payload;
    },
    dataForSignUp(state, payload: any) {
      state.dataForSignUp = payload;
    },
  },
});
