









import Vue from 'vue';
import VtqProducts from '@/components/VtqProducts.vue';
import { getProducts } from '@/resourceUtils';
import { getOrganizationMembershipBasedFilteredArrayForCurrUser } from '@/util';

export default Vue.extend({
  components: {
    VtqProducts,
  },
  computed: {
    signInComplete(): boolean {
      return this.$store.state.signInComplete;
    },
    products(): Product[] {
      return getOrganizationMembershipBasedFilteredArrayForCurrUser(this.$store.state.products) as Product[];
    },
  },
  watch: {
    async signInComplete(val) {
      if (val) {
        await getProducts();
      }
    },
  },
  async mounted() {
    await getProducts();
  },
});
