







































import Vue from 'vue';
import VtqDataTableInfo from '@/components/VtqDataTableInfo.vue';
import papaparse from 'papaparse';
import { getContactPageContent, getTerritoryManagers } from '@/resourceUtils';
import { currFirestore, firebaseCredentials, hideLoading, mdToHtml, showError, showLoading, showSuccess } from '@/util';
import { Loader } from '@googlemaps/js-api-loader';
import { getDoc, doc, setDoc, collection, Timestamp, getDocs, deleteDoc } from 'firebase/firestore';
import Spacer from '@/components/Spacer.vue';
import { eUserRoles } from '@/enums';

export default Vue.extend({
  components: { VtqDataTableInfo, Spacer },
  data(): {
    page: number;
    pageCount: number;
    itemsPerPage: number;
    content: any;
    distancesWereComputed: boolean;
    territoryManagers: TerritoryManager[];
    headers: TableHeaders;
    search: string;
    zipSearch: string;
  } {
    const headers: TableHeaders = [
      {
        text: 'Name',
        value: 'fullName',
        sortable: true,
      },
      {
        text: 'Focus',
        value: 'focus',
        sortable: true,
      },
      {
        text: 'Address',
        value: 'formattedAddress',
        sortable: false,
      },
      {
        text: 'Phone',
        value: 'phone',
        sortable: false,
      },
      {
        text: 'Email',
        value: 'email',
        sortable: false,
      },
      {
        text: 'Distance (km)',
        value: 'distance',
        sortable: true,
      },
    ];
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      content: null,
      distancesWereComputed: false,
      territoryManagers: [],
      headers,
      search: '',
      zipSearch: '',
    };
  },
  watch: {
    signInComplete(val) {
      if (val) {
        this.onLoad();
      }
    },
  },
  computed: {
    isAdmin(): boolean {
      return this.$store.state.currUser.role === eUserRoles.admin;
    },
    signInComplete(): boolean {
      return this.$store.state.signInComplete;
    },
    filteredTerritoryManagers(): TerritoryManager[] {
      const filteredManagers = this.territoryManagers.filter((manager: TerritoryManager) => {
        let passesTagTest = true;
        let passesSearchText = true;
        if (this.search) {
          const propMatches = (propName: string) => {
            return (manager as any)[propName].toLowerCase().includes(this.search.toLowerCase());
          };
          passesSearchText = propMatches('fullName') || propMatches('formattedAddress');
        }
        return passesTagTest && passesSearchText;
      });
      return filteredManagers;
    },
  },
  mounted() {
    this.onLoad();
  },
  methods: {
    searchZip() {
      if (this.zipSearch.length === 5) {
        getDoc(doc(currFirestore, 'zipCodeMap', this.zipSearch)).then((docSnap) => {
          if (docSnap.exists()) {
            const manager = docSnap.data()?.manager || '';
            this.search = manager;
          } else {
            showError('No Territory Managers found for this zip.');
          }
        });
      } else {
        showError('Zip must be 5 characters.');
      }
    },
    async onLoad() {
      this.content = await getContactPageContent();
      this.territoryManagers = await getTerritoryManagers();
      navigator.permissions.query({ name: 'geolocation' }).then((result) => {
        if (result.state == 'granted') {
          this.sortByDistance();
        }
      });
    },
    sortByDistance() {
      if (navigator.geolocation) {
        navigator.permissions.query({ name: 'geolocation' }).then((result) => {
          if (result.state == 'denied') {
            showError('Location permissions were previously denied. You can grant geolocation permissions for this app in your browser settings.');
            return;
          }
        });

        navigator.geolocation.getCurrentPosition((position) => {
          if (!position) {
            showError('Could not get position.');
            return;
          }
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;

          //https://stackoverflow.com/questions/27928/calculate-distance-between-two-latitude-longitude-points-haversine-formula
          function getDistanceFromLatLonInKm(lat1: number, lon1: number, lat2: number, lon2: number) {
            var p = 0.017453292519943295; // Math.PI / 180
            var c = Math.cos;
            var a = 0.5 - c((lat2 - lat1) * p) / 2 + (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2;
            return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
          }

          this.territoryManagers = this.territoryManagers
            .map((tm) => {
              if (tm.latLng) {
                return { ...tm, distance: Math.round(getDistanceFromLatLonInKm(tm.latLng[0], tm.latLng[1], lat, lng)) };
              }
              return { ...tm, distance: Number.MAX_SAFE_INTEGER };
            })
            .sort((a, b) => {
              return a.distance - b.distance;
            });
          this.distancesWereComputed = true;
        });
      } else {
        showError('Your browser does not support geolocation.');
      }
    },
    async uploadTerritoryManagersZipCodeMap(csvFile: File) {      
      const file = csvFile;
      if (!file) {
        alert('Please select a CSV file.');
        return;
      }      

      showLoading();

      await getDocs(collection(currFirestore, 'zipCodeMap')).then((querySnap) => {
        querySnap.forEach((docSnap) => {
          deleteDoc(docSnap.ref);
        });
      });

      papaparse.parse(file, {
        header: true, // Treat the first row as header names
        dynamicTyping: false, // Automatically convert numeric values
        complete(results: any) {
          const zipMap: { zip: string; manager: string }[] = results.data;
          const promises: Promise<void>[] = [];
          zipMap.forEach((z) => {
            if (!z.manager) {
              // Prevent blank entries from being entered.
              return;
            }

            const newDoc = doc(collection(currFirestore, 'zipCodeMap'), z.zip);
            const promise = setDoc(newDoc, {
              manager: z.manager,
            });

            promises.push(promise);
          });

          Promise.all(promises)
            .then(() => {
              hideLoading();
              showSuccess('Zip code mapping successfully uploaded.');
            })
            .catch((err: any) => {
              hideLoading();
              showError('Could not upload the zip code mapping.', err);
            });
        },
        error(error: any) {
          hideLoading();
          console.error('CSV parsing error:', error.message);
        },
      });
    },
    async uploadTerritoryManagersCsv(csvFile: File) {
      const self = this;
      const file = csvFile;
      if (!file) {
        alert('Please select a CSV file.');
        return;
      }      

      showLoading();

      const loader = new Loader({
        apiKey: firebaseCredentials.apiKey,
        version: 'weekly',
      });

      await getDocs(collection(currFirestore, 'territoryManagers')).then((querySnap) => {
        querySnap.forEach((docSnap) => {
          deleteDoc(docSnap.ref);
        });
      });

      loader.load().then((google) => {
        const geocoder = new google.maps.Geocoder();

        papaparse.parse(file, {
          header: true, // Treat the first row as header names
          dynamicTyping: false, // Automatically convert numeric values
          complete(results: any) {
            const allTerritoryManagers: TerritoryManager[] = results.data;
            const promises: Promise<void>[] = [];
            const allFullDataTerritoryManagers: TerritoryManager[] = [];
            allTerritoryManagers.forEach((tm) => {
              if (!tm.fullName) {
                // Prevent blank entries from being entered.
                return;
              }
              const promise: Promise<void> = new Promise((resolve, reject) => {
                geocoder
                  .geocode({
                    componentRestrictions: {
                      country: 'US',
                      postalCode: tm.zip,
                    },
                  })
                  .then((res: any) => {
                    const result = res?.results?.[0];
                    if (result) {
                      const formattedAddress = result.formatted_address;
                      const placeId = result.place_id;
                      const latLng = [result.geometry.location.lat(), result.geometry.location.lng()];

                      const newDoc = doc(collection(currFirestore, 'territoryManagers'));
                      const fullManagerObject = {
                        ...tm,
                        docId: newDoc.id,
                        formattedAddress,
                        placeId,
                        latLng,
                        visible: true,
                        dateCreated: Timestamp.fromMillis(Date.now()),
                        dateUpdated: Timestamp.fromMillis(Date.now()),
                      };
                      allFullDataTerritoryManagers.push(fullManagerObject)
                      setDoc(newDoc, fullManagerObject)
                        .then(() => {
                          resolve();
                        })
                        .catch(() => {
                          reject();
                        });
                    }
                  })
                  .catch(() => {
                    reject();
                  });
              });
              promises.push(promise);
            });

            Promise.all(promises)
              .then(() => {
                hideLoading();
                showSuccess('Territory managers successfully uploaded.');
                self.territoryManagers = allFullDataTerritoryManagers;                
              })
              .catch((err: any) => {
                hideLoading();
                showError('Could not upload the territory managers.', err);
              });
          },
          error(error: any) {
            hideLoading();
            console.error('CSV parsing error:', error.message);
          },
        });
      });
    },
    getPhoneNumberAsInt(phone: string) {
      if (!phone) {
        return 0;
      }
      return parseInt(phone.replace(/[^0-9]/g, ''));
    },
    mdToHtml(md: string, removeHtml = false) {
      return mdToHtml(md, removeHtml);
    },
  },
});
