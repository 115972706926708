









































import { fbAuth, showError, showSnackBar, updateUserDoc } from '@/util';
import { updateEmail, updatePassword, updateProfile, User } from 'firebase/auth';
import validationRules from '@/validation-rules';
import Vue from 'vue';
import { getOrganizations } from '@/resourceUtils';

export default Vue.extend({
  data(): {
    rules: typeof validationRules;
    isUserFormValid: boolean;
    isPasswordFormValid: boolean;
    password: string;
    passwordConfirm: string;
    emailWasChanged: boolean;
    organizations: Organization[];
    adminSelectedOrganizations: string[];
    userForm: {
      email: string;
      displayName: string;
      companyName: string;
      phone: string;
      importantEmailNotificationsEnabled: boolean;
      marketingEmailNotificationsEnabled: boolean;
      importantSmsNotificationsEnabled: boolean;
      marketingSmsNotificationsEnabled: boolean;
      // userSelectedOrganizations: {
      //   docId: string;
      //   name: string;
      // }[];
    };
  } {    
    return {
      rules: validationRules,
      isUserFormValid: false,
      isPasswordFormValid: false,
      password: '',
      passwordConfirm: '',
      emailWasChanged: false,
      organizations: [],
      adminSelectedOrganizations: this.$store.state.currUser.adminSelectedOrganizations?.map((o: ClientSideOrg) => o.name) || [],
      userForm: {
        email: this.$store.state.currUser.email,
        displayName: this.$store.state.currUser.displayName,
        companyName: this.$store.state.currUser.companyName,
        phone: this.$store.state.currUser.phone,
        importantEmailNotificationsEnabled: this.$store.state.currUser.importantEmailNotificationsEnabled,
        marketingEmailNotificationsEnabled: this.$store.state.currUser.marketingEmailNotificationsEnabled,
        importantSmsNotificationsEnabled: this.$store.state.currUser.importantSmsNotificationsEnabled,
        marketingSmsNotificationsEnabled: this.$store.state.currUser.marketingSmsNotificationsEnabled,
        // userSelectedOrganizations: this.$store.state.currUser.userSelectedOrganizations,
      },
    };
  },
  computed: {
    signInComplete(): boolean {
      return this.$store.state.signInComplete;
    },
    hasPermanentPremium(): boolean {
      return this.$store.state.currUserModeGateway?.hasPermanentPremium;
    },
    comparePasswordRules(): string | boolean {
      return this.password !== this.passwordConfirm ? 'Passwords do not match' : true;
    },
  },
  async mounted() {
    this.setOrgs();
  },
  watch: {
    async signInComplete(val) {
      if (val) {
        this.userForm.email = this.$store.state.currUser.email;
        this.userForm.displayName = this.$store.state.currUser.displayName;
        this.userForm.companyName = this.$store.state.currUser.companyName;
        this.userForm.phone = this.$store.state.currUser.phone;
        // this.userForm.userSelectedOrganizations = this.$store.state.currUser.userSelectedOrganizations;
        this.userForm.importantEmailNotificationsEnabled = this.$store.state.currUser.importantEmailNotificationsEnabled;
        this.userForm.marketingEmailNotificationsEnabled = this.$store.state.currUser.marketingEmailNotificationsEnabled;
        this.userForm.importantSmsNotificationsEnabled = this.$store.state.currUser.importantSmsNotificationsEnabled;
        this.userForm.marketingSmsNotificationsEnabled = this.$store.state.currUser.marketingSmsNotificationsEnabled;
        this.setOrgs();
      }
    },
  },
  methods: {
    async setOrgs() {
      this.organizations = await getOrganizations();      
      // Remove organizations that were specified by the admin.
      this.adminSelectedOrganizations = this.$store.state.currUser.adminSelectedOrganizations?.map((o: ClientSideOrg) => o.name);
      this.organizations = this.organizations.filter((o) => !this.$store.state.currUser.adminSelectedOrganizations?.map((ao: ClientSideOrg) => ao.docId).includes(o.docId));
    },
    updatePassword() {
      if ((this.$refs.passwordform as any).validate()) {
        const user: User | null = fbAuth.currentUser;
        if (user) {
          updatePassword(user, this.password)
            .then(() => {
              showSnackBar('Password updated successfully.');
            })
            .catch((error: any) => {
              switch (error.code) {
                case 'auth/weak-password':
                  showError('This password is too weak, it must be at least 6 characters.');
                  break;
                case 'auth/requires-recent-login':
                  showError('Recent sign in required. Sign out and sign back in to do this.');
                  break;
                default:
                  showError('An error occurred. Please refresh the page and try again.', error);
                  break;
              }
            })
            .finally(() => {
              this.password = '';
              this.passwordConfirm = '';
              (this.$refs.passwordform as any).reset();
            });
        } else {
          showError('Cannot update password. No user exists');
        }
      }
    },
    updateAccountInfo() {
      if ((this.$refs.userForm as any).validate()) {
        const user = fbAuth.currentUser;

        if (!user) {
          showError('No user exists');
          return;
        }

        const promises: Promise<void>[] = [];

        if (this.userForm.email !== user.email) {
          promises.push(
            updateEmail(user, this.userForm.email).then(() => {
              this.emailWasChanged = true;
            })
          );
        }

        if (this.userForm.displayName !== user.displayName) {
          promises.push(updateProfile(user, { displayName: this.userForm.displayName }));
        }

        Promise.all(promises)
          .then(() => {
            updateUserDoc(this.userForm);
          })
          .catch((error: any) => {
            switch (error.code) {
              case 'auth/requires-recent-login':
                showError('Recent sign in required. Sign out and sign back in to do this.');
                break;
              default:
                showError('Could not update your account. Please refresh the page and try again.', error);
                break;
            }
          });
      }
    },
  },
});
