
























import { deferredPrompt, isiOS, onShowPWAPrompt } from '@/util';
import Vue from 'vue';

enum browsers {
  chrome,
  unknown,
  firefox,
  edge,
  safari,
  brave,
}

export default Vue.extend({
  data(): {
    browsers: typeof browsers;
    deferredPrompt: BeforeInstallPromptEvent | null;
    isAlreadyInstalled: boolean;
  } {
    return {
      browsers,
      deferredPrompt: deferredPrompt,
      isAlreadyInstalled: window.matchMedia('(display-mode: standalone)').matches,
    };
  },
  computed: {
    currBrowser(): browsers {
      const ua: string = navigator.userAgent.toLowerCase();
      if (isiOS() || (ua.includes('safari') && !ua.includes('chrome'))) {
        return this.browsers.safari;
      } else if (ua.includes('chrome') && !ua.includes('chromium')) {
        return this.browsers.chrome;
      } else if (ua.includes('firefox')) {
        return this.browsers.firefox;
      } else if (ua.includes('edge')) {
        return this.browsers.edge;
      } else if (ua.includes('brave')) {
        return this.browsers.brave;
      }
      return this.browsers.unknown;
    },
  },
  methods: {
    onShowPWAPrompt() {
      onShowPWAPrompt();
      this.deferredPrompt = null;
    },
  },
});
