









































































































import Vue from 'vue';
import { isMobileCheck } from '@/util';
import { resourceCollectionMixin } from '@/mixins';

export default Vue.extend({
  mixins: [resourceCollectionMixin],
  data(): {
    headers: TableHeaders;
    currContent: string;
    sampleCarouselIndex: number;
  } {
    const headers: TableHeaders = [
      {
        text: 'Name',
        value: 'name',
        sortable: true,
        width: '25%',
      },
      {
        text: 'Categories',
        value: 'tags',
        sortable: true,
        width: '30%',
      },
    ];
    if (!isMobileCheck()) {
      headers.push({
        text: 'Description',
        value: 'description',
        sortable: false,
        width: '45%',
        filterable: false,
      });
    }
    return {
      headers,
      currContent: '',
      sampleCarouselIndex: 0,
    };
  },
  computed: {
    filteredSamples(): Sample[] {
      const self: any = this;
      const filteredSamples = self.items.filter((sample: Sample) => {
        let passesTagTest = true;
        let passesSearchText = true;
        if (self.selectedTags && self.selectedTags.length) {
          // If this sample contains a selected tag, add it to the list.
          passesTagTest = self.selectedTags.some((tag: string) => {
            return sample.tags?.includes(tag);
          });
        }
        if (self.search) {
          passesSearchText = sample.name.toLowerCase().includes(self.search.toLowerCase());
        }
        return passesTagTest && passesSearchText;
      });
      return filteredSamples;
    },
    filteredSamplesInPage(): Sample[] {
      const startIndex = (this.$data.page - 1) * this.$data.itemsPerPage;
      return this.filteredSamples.slice(startIndex, startIndex + this.$data.itemsPerPage);
    },
  },
  mounted() {
    this.$data.pageCount = Math.ceil(this.filteredSamples.length / this.$data.itemsPerPage);
  },
  watch: {
    filteredSamples(val) {
      this.$data.pageCount = Math.ceil(val.length / this.$data.itemsPerPage);
    },
  },
  methods: {
    formatPrice(price: number) {
      if (!price) {
        return '-';
      }
      return price.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
      });
    },
  },
});
