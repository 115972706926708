









import Vue from 'vue';
import { getBlogPosts } from '@/resourceUtils';
import VtqBlogPosts from '@/components/VtqBlogPosts.vue';

export default Vue.extend({
  components: {
    VtqBlogPosts,
  },
  data(): {
    blogPostCarouselIndex: number;
    showBlogPostDialog: boolean;    
  } {
    return {
      blogPostCarouselIndex: 0,
      showBlogPostDialog: false,      
    };
  },
  computed: {
    signInComplete(): boolean {
      return this.$store.state.signInComplete;
    },
    blogPosts(): BlogPost[] {
      return this.$store.state.blogPosts;
    },
  },
  watch: {
    async signInComplete(val) {
      if (val) {
        await getBlogPosts();
      }
    },
  },
  async mounted() {
    await getBlogPosts();
  },
});
