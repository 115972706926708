











import { getAboutPageContent } from '@/resourceUtils';
import { mdToHtml } from '@/util';
import Vue from 'vue';

export default Vue.extend({
  data(): {
    content: any;
  } {
    return {
      content: null,
    };
  },
  computed: {    
    signInComplete(): boolean {
      return this.$store.state.signInComplete;
    },
  },
  watch: {
    async signInComplete(val) {
      if (val) {
        this.content = await getAboutPageContent();
      }
    },
  },
  async mounted() {
    this.content = await getAboutPageContent();
  },
  methods: {
    mdToHtml(md: string, removeHtml = false) {
      return mdToHtml(md, removeHtml);
    },
  },
});
