









import Vue from 'vue';

enum eTags {
  // Products
  companionAnimal = 'companionAnimal',
  largeAnimal = 'largeAnimal',
  parasitology = 'parasitology',
  toxicology = 'toxicology',
  dermatology = 'dermatology',
  jointSupport = 'jointSupport',
  painManagement = 'painManagement',
  behavior = 'behavior',
  renalSupport = 'renalSupport',
  gastrointestinal = 'gastrointestinal',
  dentalCare = 'dentalCare',
  immuneSupport = 'immuneSupport',
  product = 'product',
  financial = 'financial',
  misc = 'misc',
  limitedTime = 'limitedTime',
  exclusive = 'exclusive',
  award = 'award',
}

export default Vue.extend({
  props: {
    tags: {
      type: Array,
      default: () => [],
    },
    small: {
      type: Boolean,
      default: false,
    },
    absolute: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    formatTag(tag: string) {
      switch (tag) {
        case eTags.companionAnimal:
          return {
            text: 'Companion',
            color: '#388E3C',
            icon: 'dog',
            isFor: 'products',
          };
        case eTags.largeAnimal:
          return {
            text: 'Large Animal',
            color: '#0097A7',
            icon: 'horse',
            isFor: 'products',
          };
        case eTags.parasitology:
          return {
            text: 'Parasitology',
            color: '#B71C1C',
            icon: 'spider',
            isFor: 'products',
          };
        case eTags.toxicology:
          return {
            text: 'Toxicology',
            color: '#4A148C',
            icon: 'biohazard',
            isFor: 'products',
          };
        case eTags.dermatology:
          return {
            text: 'Dermatology',
            color: '#1A237E',
            icon: 'allergies',
            isFor: 'products',
          };
        case eTags.jointSupport:
          return {
            text: 'Joints',
            color: '#01579B',
            icon: 'project-diagram',
            isFor: 'products',
          };
        case eTags.painManagement:
          return {
            text: 'Pain Relief',
            color: '#004D40',
            icon: 'user-injured',
            isFor: 'products',
          };
        case eTags.behavior:
          return {
            text: 'Behavior',
            color: '#33691E',
            icon: 'smile-beam',
            isFor: 'products',
          };
        case eTags.renalSupport:
          return {
            text: 'Renal Support',
            color: '#F57F17',
            icon: 'kidneys',
            isFor: 'products',
          };
        case eTags.gastrointestinal:
          return {
            text: 'Gastrointestinal',
            color: '#E65100',
            icon: 'stomach',
            isFor: 'products',
          };
        case eTags.dentalCare:
          return {
            text: 'Dental',
            color: '#546E7A',
            icon: 'tooth',
            isFor: 'products',
          };
        case eTags.immuneSupport:
          return {
            text: 'Immune Support',
            color: '#263238',
            icon: 'shield-virus',
            isFor: 'products',
          };
        case eTags.product:
          return {
            text: 'Product',
            color: '#E53935',
            icon: 'box',
            isFor: 'blogPosts',
          };
        case eTags.financial:
          return {
            text: 'Financial',
            color: '#43A047',
            icon: 'money-bill-wave',
            isFor: 'blogPosts',
          };
        case eTags.award:
          return {
            text: 'Award',
            color: '#FBC02D',
            icon: 'trophy',
            isFor: 'blogPosts',
          };
        case eTags.misc:
          return {
            text: 'Misc',
            color: '#607D8B',
            icon: 'circle',
            isFor: 'blogPosts',
          };
        case eTags.limitedTime:
          return {
            text: 'Limited Time',
            color: '#F44336',
            icon: 'clock',
            isFor: 'promotions',
          };
        case eTags.exclusive:
          return {
            text: ' ',
            color: '#F9A825',
            icon: 'star',
            isFor: 'promotions',
          };
      }
    },
  },
});
