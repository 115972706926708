















































































import Vue from 'vue';
import { fbAuth, setUpForSignedInAuthUser, showConfirmation, showError, showNotice, signOutAccount } from '@/util';
import { createUserWithEmailAndPassword, sendPasswordResetEmail, signInWithEmailAndPassword, updateProfile } from 'firebase/auth';
import validationRules from '@/validation-rules';

export default Vue.extend({
  name: 'App',
  data(): {
    rules: typeof validationRules;
    showPasswordReset: boolean;
    showPassword: boolean;
    showSignUpForm: boolean;
    emailForPasswordReset: string;
    signUpForm: {
      email: string;
      displayName: string;
      companyName: string;
      phone: string;
      password: string;
      passwordConfirm: string;
    };
    signInform: {
      email: string;
      password: string;
    };
  } {
    return {
      rules: validationRules,
      showPasswordReset: false,
      showPassword: false,
      showSignUpForm: false,
      emailForPasswordReset: '',
      signUpForm: {
        email: '',
        displayName: '',
        companyName: '',
        phone: '',
        password: '',
        passwordConfirm: '',
      },
      signInform: {
        email: '',
        password: '',
      },
    };
  },
  computed: {
    isLoggedIn(): boolean {
      return this.$store.state.isLoggedIn;
    },
    comparePasswordRules(): string | boolean {
      return this.signUpForm.password !== this.signUpForm.passwordConfirm ? 'Passwords do not match' : true;
    },
  },
  watch: {
    isLoggedIn(val) {
      if (val) {
        this.resetFormData();
      }
    },
    showSignUpForm() {
      if (this.$refs.signInForm) (this.$refs.signInForm as any).reset();
      if (this.$refs.signUpForm) (this.$refs.signUpForm as any).reset();
    },
  },
  mounted() {
    document.addEventListener('keypress', (event) => {
      // Block enter key submission if a dialoge is shown on top of sign in form.
      if (!this.isLoggedIn && event.key === 'Enter' && !this.showPasswordReset) {
        event.preventDefault();
        if (this.showSignUpForm) {
          this.signUp();
        } else {
          this.signIn();
        }
      }
    });
  },
  methods: {
    signOutAccount() {
      signOutAccount();
    },
    onResetPassword() {
      if ((this.$refs.resetForm as any).validate()) {
        showConfirmation('Are you sure you want to reset your password?', () => {
          sendPasswordResetEmail(fbAuth, this.emailForPasswordReset)
            .then(() => {
              showNotice(`A password reset link has been sent to ${this.emailForPasswordReset}.`);
              this.showPasswordReset = false;
            })
            .catch((error: any) => {
              showError('Could not reset password.' + (error.message || error));
            });
        });
      }
    },
    signUp() {
      if ((this.$refs.signUpForm as any).validate()) {
        this.$store.commit('dataForSignUp', {
          displayName: this.signUpForm.displayName,
          companyName: this.signUpForm.companyName,
          phone: this.signUpForm.phone,
        });
        createUserWithEmailAndPassword(fbAuth, this.signUpForm.email, this.signUpForm.password)
          .then((userCredential) => {
            (this.$parent as any).displayName = this.signUpForm.displayName;
            updateProfile(userCredential.user, {
              displayName: this.signUpForm.displayName,
            });
          })
          .catch((error: any) => {
            if (error.code === 'auth/email-already-in-use') {
              showError('Email already in use.');
            } else {
              signOutAccount();
              showError('There was a problem creating the user account. ', error);
            }
          });
      }
    },
    signIn() {
      if ((this.$refs.signInForm as any).validate()) {
        //Sign in the user.
        return signInWithEmailAndPassword(fbAuth, this.signInform.email, this.signInform.password)
          .then((authUser) => {
            if (authUser.user) {
              setUpForSignedInAuthUser(authUser.user);
            } else {
              showError('No user object on auth user for sign in.');
            }
          })
          .catch((error: any) => {
            switch (error.code) {
              case 'auth/wrong-password':
                showError('Invalid username/password.');
                break;
              case 'auth/too-many-requests':
                showError('Too many failed attempts. Reset your password to gain access now or you can try again later.');
                break;
              case 'auth/user-not-found':
                showError('Email not found. Sign up below.');
                break;
              default:
                showError('An error occurred. Please refresh the page and try again.', error);
                break;
            }
          });
      }
    },
    resetFormData() {
      this.signUpForm.email = '';
      this.signUpForm.displayName = '';
      this.signUpForm.password = '';
      this.signUpForm.passwordConfirm = '';
      this.signInform.email = '';
      this.signInform.password = '';
    },
  },
});
