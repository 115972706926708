const maxNameLength = 50;
const firebasePasswordMinChars = 5;
const maxPasswordCharacterLength = 100;
const phoneRegEx = /^[0-9()+-\s]+$/;

const validationRules = {
  requiredRules: [(v: any) => !!v || v === false || v === 0 || 'Is required.'],
  requiredPhoneRules: [(v: string) => !!v || 'Is required.', (v: string) => (v ? phoneRegEx.test(v) || 'Must be a valid phone number' : true)],
  requiredEmailRules: [(v: string) => !!v || 'Is required.', (v: string) => (v ? /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/.test(v) || 'Must be a valid email.' : true)],
  requiredNameRules: [(v: string) => !!v || 'Is required.', (v: string) => (v && v.length < maxNameLength) || `Must be less than or equal to ${maxNameLength}`],
  requiredPasswordRules: [
    (v: string) => !!v || 'Is required.',
    (v: string) => v === '' || (v && v.length) > firebasePasswordMinChars || `Must be greater than ${firebasePasswordMinChars}`,
    (v: string) => v === '' || (v && v.length) < maxPasswordCharacterLength || `Must be less than ${maxPasswordCharacterLength}`,
  ],
};

export default validationRules;
