import './registerServiceWorker';
import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { marked } from 'marked';
import {
  faArrowLeft,
  faBars,
  faCalendarAlt,
  faCaretDown,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faClock,
  faExclamationCircle,
  faEye,
  faEyeSlash,
  faSearch,
  faSignInAlt,
  faSortUp,
  faSync,
  faTimes,
  faUserPlus,
  faFile,
  faFileAlt,
  faFileArchive,
  faFileAudio,
  faFileCode,
  faFileExcel,
  faFileImage,
  faFileInvoice,
  faFileInvoiceDollar,
  faFilePdf,
  faFilePowerpoint,
  faFileVideo,
  faFileWord,
  faThLarge,
  faList,
  faInbox,
  faDog,
  faHorse,
  faTooth,
  faShieldVirus,
  faStomach,
  faUserInjured,
  faBiohazard,
  faAllergies,
  faKidneys,
  faSmileBeam,
  faSpider,
  faMoneyBillWave,
  faBox,
  faTrophy,
  faStar,
  faHandPointUp,
  faProjectDiagram,
  faCheck,
  faPlus,
} from '@fortawesome/pro-light-svg-icons';

import { faNewspaper, faTachometerFast, faBoxes, faUsers, faMobile, faQuestion, faMegaphone, faCog, faSignOutAlt, faEnvelope, faSun, faMoon, faBookOpen, faGraduationCap, faClinicMedical, faShoppingBag } from '@fortawesome/pro-duotone-svg-icons';
import { getEmptyUser, store } from '@/store';
import vuetify from '@/plugins/vuetify';
import VtqBackButton from '@/components/VtqBackButton.vue';
import VtqTooltip from '@/components/VtqTooltip.vue';
import VtqTags from '@/components/VtqTags.vue';
import VtqHeader from '@/components/VtqHeader.vue';
import VtqEmptyResults from '@/components/VtqEmptyResults.vue';
import Spacer from '@/components/Spacer.vue';
import { productionFirebaseCredentials, stagingFirebaseCredentials } from '@/firebase-credentials';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { addDoc, collection, doc, Firestore, FirestoreSettings, getDoc, getFirestore, initializeFirestore, setDoc, Timestamp, updateDoc } from 'firebase/firestore';
import { browserLocalPersistence, getAuth, setPersistence, User } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import 'normalize.css';
import { faSquare } from '@fortawesome/pro-regular-svg-icons';
import { faCheckSquare, faCircle, faPaperclip, faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { getOrganizations, getProducts } from './resourceUtils';
import { getAnalytics } from 'firebase/analytics';

// Vuetify alts:
library.add(
  faTimes,
  faMegaphone,
  faBoxes,
  faEnvelope,
  faUsers,
  faQuestion,
  faMobile,
  faCog,
  faSignOutAlt,
  faArrowLeft,
  faBars,
  faSignInAlt,
  faUserPlus,
  faExclamationCircle,
  faEye,
  faEyeSlash,
  faSync,
  faSortUp,
  faCaretDown,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faClock,
  faCalendarAlt,
  faSearch,
  faSquare,
  faCheckSquare,
  faCircle,
  faFile,
  faFileAlt,
  faFileArchive,
  faFileAudio,
  faFileCode,
  faFileExcel,
  faFileImage,
  faFileInvoice,
  faFileInvoiceDollar,
  faFilePdf,
  faFilePowerpoint,
  faFileVideo,
  faFileWord,
  faInbox,
  faNewspaper,
  faTachometerFast,
  faThLarge,
  faList,
  faSun,
  faMoon,
  faDog,
  faHorse,
  faTooth,
  faShieldVirus,
  faStomach,
  faUserInjured,
  faBiohazard,
  faAllergies,
  faKidneys,
  faSmileBeam,
  faProjectDiagram,
  faSpider,
  faMoneyBillWave,
  faBox,
  faCircle,
  faClock,
  faStar,
  faTrophy,
  faHandPointUp,
  faBookOpen,
  faCheck,
  faGraduationCap,
  faClinicMedical,
  faPlus,
  faShoppingBag,
  faPaperclip,
  faTimesCircle,
);

export const nonDebugLog = (...args: any) => {
  console.log(...args);
};

Vue.component('fa', FontAwesomeIcon);
Vue.component('font-awesome-icon', FontAwesomeIcon); // For Vuetify.
Vue.component('vtq-back-button', VtqBackButton);
Vue.component('vtq-empty-results', VtqEmptyResults);
Vue.component('vtq-tooltip', VtqTooltip);
Vue.component('vtq-tags', VtqTags);
Vue.component('vtq-header', VtqHeader);
Vue.component('spacer', Spacer);

Vue.config.productionTip = false;

export let rootComponent: any;
export let deferredPrompt: BeforeInstallPromptEvent | null = null;
let loadingCount = 0;

window.addEventListener('beforeinstallprompt', (e) => {
  // Prevent Chrome 67 and earlier from automatically showing the prompt
  e.preventDefault();
  // Stash the event so it can be triggered later.
  deferredPrompt = e as BeforeInstallPromptEvent;
});

export const onShowPWAPrompt = () => {
  if (deferredPrompt) {
    deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    deferredPrompt.userChoice.then(() => {
      deferredPrompt = null;
    });
  }
};

// export const isProductionEnv = (process.env.NODE_ENV === 'production' && window.location.hostname.includes('vqconnect.app')) || true;
export const isProductionEnv = process.env.NODE_ENV === 'production' && window.location.hostname.includes('vqconnect.app');
export let currFirestore: Firestore;
export let firebaseApp: FirebaseApp;
export let firebaseCredentials: {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
};

if (isProductionEnv) {
  const fireStoreSettings: FirestoreSettings = { ignoreUndefinedProperties: true };
  firebaseCredentials = productionFirebaseCredentials;
  firebaseApp = initializeApp(productionFirebaseCredentials);
  currFirestore = initializeFirestore(firebaseApp, fireStoreSettings);
} else {
  firebaseCredentials = stagingFirebaseCredentials;
  firebaseApp = initializeApp(stagingFirebaseCredentials);
  currFirestore = getFirestore(firebaseApp);
}

export const fbAnalytics = getAnalytics(firebaseApp);
export const fbAuth = getAuth(firebaseApp);
export const fbStorage = getStorage();

export const showSnackBar = (msg: string) => {
  rootComponent.showSnackbarFunc(msg);
};

export const showLoading = () => {
  if (navigator.onLine) {
    loadingCount++;
    setTimeout(() => {
      // Slight delay in case we call hideLoading immeadiately after because of an error or something of that nature.
      if (loadingCount > 0) {
        store.commit('isLoading', true);
      }
    }, 0);
  }
};

export const hideLoading = (forceLoadingCountToZero = false) => {
  loadingCount--;
  if (forceLoadingCountToZero) {
    loadingCount = 0;
  }
  setTimeout(() => {
    if (loadingCount <= 0) {
      loadingCount = 0;
      store.commit('isLoading', false);
    }
  }, 100); // Anti FOUC delay.
};

export const showError = (str: string, error?: any, reportThis = false) => {
  console.error(str);
  if (!rootComponent.isMounted) {
    return;
  }
  if (reportThis) {
    const collectionRef = collection(currFirestore, 'errors');
    addDoc(collectionRef, {
      errorString: `${str} ${error?.message || error}`,
    });
  }
  if (process.env.NODE_ENV !== 'production' && error) {
    showSnackBar(`${str} ${error?.message || error}`);
  } else {
    showSnackBar(str);
  }
};

export const showNotice = (str: string) => {
  if (!rootComponent.isMounted) {
    return;
  }
  showSnackBar(str);
};

export const showSuccess = (str: string) => {
  if (!rootComponent.isMounted) {
    return;
  }
  showSnackBar(str);
};

export const showWarning = (str: string) => {
  if (!rootComponent.isMounted) {
    return;
  }
  showSnackBar(str);
};

export const showConfirmation = (str: string, onConfirmFunc: any) => {
  rootComponent.showConfirmationDialogFunc(str, onConfirmFunc);
};

export const showPrompt = (str: string, onConfirmFunc: any) => {
  rootComponent.showConfirmationDialogFunc(str, onConfirmFunc, false);
};

export const writeToCollection = (collectionName: string, dataArray: any[]) => {
  dataArray.forEach((data) => {
    const newDoc = doc(collection(currFirestore, collectionName));
    setDoc(newDoc, { ...data, visible: true, dateCreated: Timestamp.fromMillis(Date.now()), dateUpdated: Timestamp.fromMillis(Date.now()) });
  });
};

export const setUpForSignedInAuthUser = (user: User) => {
  store.commit('isLoggedIn', true);
  const userId = user.uid;
  hideLoading();

  let userData: PlatformUser;

  async function onUserDocReady(userDataFromDatabase: PlatformUser | null = null) {
    getProducts();

    const organizations = await getOrganizations();

    const adminSelectedOrganizations: ClientSideOrg[] =
      userDataFromDatabase?.adminSelectedOrganizationEntities
        ?.map((r) => {
          const org = organizations.find((o) => {
            return o.docId === r.id;
          });
          return {
            docId: org?.docId || '',
            name: org?.name || '',
          };
        })
        .filter((o) => o.docId) || []; // Remove deleted organizations.

    updateUserLocal({ ...userData, adminSelectedOrganizations });
    store.commit('signInComplete', true);
  }

  getDoc(getUserDoc())
    .then((userDocSnap) => {
      if (userDocSnap.exists()) {
        // Signing in as an existing user.
        const userDataFromDatabase = userDocSnap.data() as PlatformUser;

        userData = {
          ...getEmptyUser(),
          ...userDataFromDatabase,
        } as PlatformUser;
        onUserDocReady(userDataFromDatabase);
      } else {
        userData = {
          ...getEmptyUser(),
          dateCreated: Timestamp.fromMillis(Date.now()),
          dateUpdated: Timestamp.fromMillis(Date.now()),
          docId: userId,
          displayName: store.state.dataForSignUp.displayName || '',
          companyName: store.state.dataForSignUp.companyName || '',
          phone: store.state.dataForSignUp.phone || '',
          email: user.email || '',
        };
        setDoc(getUserDoc(), userData).then(() => {
          onUserDocReady();
        });
      }
    })
    .catch((error: any) => {
      showError('Could not get user account', error, true);
      hideLoading();
    });
};

export const signOutAccount = (): Promise<void> => {
  showLoading();
  return fbAuth
    .signOut()
    .then(() => {
      store.commit('reset');
    })
    .catch((error: any) => {
      showError('Could not sign out.', error);
    })
    .finally(() => {
      hideLoading();
    });
};

export const copyLink = (link: string) => {
  navigator.clipboard.writeText(link).then(
    function () {
      showSuccess('Copied to clipboard');
    },
    function () {
      showError('Could not copy text');
    }
  );
};

export const isMobileCheck = (): boolean => {
  let check = false;
  const typedWindow: any = window;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || typedWindow.opera);
  return check;
};

export const saveSettings = (update: Partial<PlatformUser>) => {
  if (!store.state.isLoggedIn) {
    return;
  }
  updateUserDoc({
    ...update,
  });
};

export const getUserDoc = () => {
  const userId = getAuth(firebaseApp).currentUser?.uid as string;
  return doc(currFirestore, 'users', userId);
};

export const updateUserLocal = (update: Partial<PlatformUser>) => {
  store.commit('currUser', {
    ...store.state.currUser,
    ...update,
    dateUpdated: Timestamp.fromMillis(Date.now()),
  });
};

export const updateUserDoc = (update: any, failSilently = false) => {
  const revertUpdate = { ...store.state.currUser };
  updateUserLocal(update);
  return updateDoc(getUserDoc(), {
    ...update,
    dateUpdated: Timestamp.fromMillis(Date.now()),
  })
    .then(() => {
      showSuccess('Update successful.');
    })
    .catch((error: any) => {
      updateUserLocal(revertUpdate);
      if (!failSilently) {
        showError('Could not update the user', error);
      }
    });
};

export const getFileIcon = (fileName: string): string => {
  if (!fileName) {
    return 'file';
  }

  function includesAny(str: string, subStrArray: string[]) {
    return subStrArray.some((subStr) => {
      return str.includes(subStr);
    });
  }

  if (includesAny(fileName, ['.pdf'])) {
    return 'file-pdf';
  } else if (includesAny(fileName, ['.doc'])) {
    return 'file-word';
  } else if (includesAny(fileName, ['.xls'])) {
    return 'file-excel';
  } else if (includesAny(fileName, ['.ppt'])) {
    return 'file-powerpoint';
  } else if (includesAny(fileName, ['.js', '.html', '.json'])) {
    return 'file-code';
  } else if (includesAny(fileName, ['.zip'])) {
    return 'file-archive';
  } else if (includesAny(fileName, ['.mp3', '.wav', '.ogg', '.flac'])) {
    return 'file-audio';
  } else if (includesAny(fileName, ['.jpeg', '.jpg', '.png', '.gif'])) {
    return 'file-image';
  } else if (includesAny(fileName, ['.mp4', '.mkv', '.avi', '.webm', '.av1'])) {
    return 'file-video';
  }

  return 'file';
};

export const getDownloadUrlsAndCache = (pathArray: string[] = []) => {
  const downloadUrlMap: DownloadUrlMap = {};
  if (!Array.isArray(pathArray)) {
    return;
  }
  pathArray.forEach((path) => {
    if (!path) {
      return;
    }
    let paths = [path];
    if (path.includes('dynamicImages')) {
      const parts = path.split('/');
      const fileName = parts[1];
      const fileNameNoExt = parts[1].split('.')[0];
      paths = [path.replace(fileName, `resized/${fileNameNoExt}_150x150.png`), path.replace(fileName, `resized/${fileNameNoExt}_600x600.png`)];
    }
    paths.forEach((p) => {
      downloadUrlMap[p] = `https://firebasestorage.googleapis.com/v0/b/${firebaseCredentials.storageBucket}/o/${encodeURIComponent(p)}?alt=media`;
    });
  });
  store.commit('downloadUrlMap', downloadUrlMap);
};

export function getImage(path: string, size: 'small' | 'large') {
  if (!path) {
    return '';
  }
  const parts = path.split('/');
  const fileName = parts[1];
  const fileNameNoExt = parts[1].split('.')[0];
  return store.state.downloadUrlMap[path.replace(fileName, `resized/${fileNameNoExt}_${size === 'large' ? '600x600' : '150x150'}.png`)];
}

export const isiOS = () => {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
};

export const mdToHtml = (md: string, removeHtml = false) => {
  if (!md) {
    return '';
  }
  if (removeHtml) {
    // We need to do this so that line clamp works on Firefox.
    return marked.parse(md || '').replace(/<\/?[^>]+(>|$)/g, '');
  }
  return marked.parse(md || '');
};

export const getOrganizationMembershipBasedFilteredArrayForCurrUser = (itemsToCheckIfFilteredByOrganizationMembershipForCurrUser: (Product | Promotion | Literature)[]): (Product | Promotion | Literature)[] => {
  const itemsWithPromotedOrHiddenTags = itemsToCheckIfFilteredByOrganizationMembershipForCurrUser?.map((item) => {
    const currUser: PlatformUser = store.state.currUser;
    const orgsForUser = [...(currUser.adminSelectedOrganizations || []), ...(currUser.userSelectedOrganizations || [])];
    const orgs: Organization[] = store.state.organizations;
    const ret = {
      ...item,
    };
    orgsForUser.forEach((userOrg) => {
      const fullOrgObject = orgs.find((orgObj) => orgObj.docId === userOrg.docId);

      if (fullOrgObject) {
        const hiddenLiteratureIdsForOrg = (fullOrgObject.hiddenLiterature || []).map((p) => p.id);
        const hiddenSampleIdsForOrg = (fullOrgObject.hiddenSamples || []).map((p) => p.id);
        const hiddenProductIdsForOrg = (fullOrgObject.hiddenProducts || []).map((p) => p.id);
        const hiddenPromotionIdsForOrg = (fullOrgObject.hiddenPromotions || []).map((p) => p.id);
        const featuredProductIdsForOrg = (fullOrgObject.featuredProducts || []).map((p) => p.id);
        const featuredPromotionIdsForOrg = (fullOrgObject.featuredPromotions || []).map((p) => p.id);
        ret.isPromoted = ret.isPromoted || [...featuredProductIdsForOrg, ...featuredPromotionIdsForOrg].includes(item.docId);
        ret.isHiddenViaOrg = ret.isHiddenViaOrg || [...hiddenProductIdsForOrg, ...hiddenPromotionIdsForOrg, ...hiddenLiteratureIdsForOrg, ...hiddenSampleIdsForOrg].includes(item.docId);
      }
    });
    return ret;
  });
  return itemsWithPromotedOrHiddenTags.filter((item) => !item.isHiddenViaOrg).sort((a, b) => Number(b.isPromoted) - Number(a.isPromoted)) || [];
};

export const initApp = async () => {
  rootComponent = new Vue({
    router,
    store,
    vuetify,
    render: (h: any) => h(App),
  } as any).$mount('#app').$children[0];

  showLoading();
  setPersistence(fbAuth, browserLocalPersistence)
    .then(() => {
      fbAuth.onAuthStateChanged((user: User | null) => {
        if (user) {
          setUpForSignedInAuthUser(user);
        } else {
          store.commit('isLoggedIn', false);
          hideLoading();
        }
      });
    })
    .catch((error: any) => {
      showError('There was a problem while setting authorization persistance.', error);
      store.commit('isLoggedIn', false);
      hideLoading();
    });
};
