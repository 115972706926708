





























































































































































import Vue from 'vue';
import { isMobileCheck } from '@/util';'firebase/firestore';
import { resourceCollectionMixin } from '@/mixins';

export default Vue.extend({
  mixins: [resourceCollectionMixin],
  data(): {
    headers: TableHeaders;
  } {
    const headers = [
      {
        text: 'Date',
        value: 'goLiveDate',
        sortable: true,
        filterable: false,
        width: '20%',
      },
      {
        text: 'Title',
        value: 'title',
        sortable: true,
        width: '55%',
      },
    ];
    if (!isMobileCheck()) {
      headers.push({
        text: 'Categories',
        value: 'tags',
        sortable: true,
        width: '25%',
      });
    }
    return {
      headers,
    };
  },
  computed: {
    filteredBlogPosts(): BlogPost[] {
      const self: any = this;      
      const filteredBlogPosts = self.items.filter((blogPost: BlogPost) => {
        let passesTagTest = true;
        let passesSearchText = true;
        if (self.selectedTags && self.selectedTags.length) {
          // If this blogPost contains a selected tag, add it to the list.
          passesTagTest = self.selectedTags.some((tag: string) => {
            return blogPost.tags?.includes(tag);
          });
        }
        if (self.search) {
          passesSearchText = blogPost.title.toLowerCase().includes(self.search.toLowerCase());
        }
        return passesTagTest && passesSearchText;
      });
      return filteredBlogPosts;
    },
    filteredBlogPostsInPage(): BlogPost[] {
      const startIndex = (this.$data.page - 1) * this.$data.itemsPerPage;
      return this.filteredBlogPosts.slice(startIndex, startIndex + this.$data.itemsPerPage);
    },
    readTimeForCurrContent(): string {
      const self: any = this;
      return `${[Math.round(self.currItem.content?.split(' ').length / 225) || 0]} min read`;
    },
  },
  mounted() {
    this.$data.pageCount = Math.ceil(this.filteredBlogPosts.length / this.$data.itemsPerPage);
  },
  watch: {
    filteredBlogPosts(val) {
      this.$data.pageCount = Math.ceil(val.length / this.$data.itemsPerPage);
    },
  },
  methods: {
    getItem(id: string): BlogPost | undefined {
      const self: any = this;
      const blogPost = self.items.find((p: BlogPost) => p.docId === id);
      if (!blogPost) {
        return;
      }
      self.currItem = blogPost;
      Vue.nextTick(() => {
        window.scrollTo(0, 0);
      });
      return blogPost;
    },
  },
});
