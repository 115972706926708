export enum eThemes {
  dark = 'dark',
  light = 'light',
}

export enum eUserRoles {
  admin = 'admin',
  applicant = 'applicant',
  partner = 'partner',
}

// export enum Distributors {
// Covetrus
// MWI
// Patterson
// Penn Vet
// Midwest
// First Vet
// Victor
// New England Animal Health
// }
// export enum GPOs {
// MClub
// TVC
// AAHA Advantage
// VMG
// }
// export enum eCorporations{
// MVP
// VetCor
// NVA
// }

export enum eOrganizationTypes {
  GPO = 'GPO',
  distributor = 'distributor',
  corporation = 'corporation',
}
