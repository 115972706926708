































import { currFirestore, firebaseCredentials, getOrganizationMembershipBasedFilteredArrayForCurrUser, showError, showSuccess } from '@/util';
import Vue from 'vue';
import VtqSamples from '@/components/VtqSamples.vue';
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import { Loader } from '@googlemaps/js-api-loader';
import Spacer from '@/components/Spacer.vue';
import { collection, doc, setDoc, Timestamp } from 'firebase/firestore';
import { getSamples } from '@/resourceUtils';

export default Vue.extend({
  components: {
    VueGoogleAutocomplete,
    Spacer,
    VtqSamples,
  },
  data(): {
    selectedSample: string[];
    addressLine2: string;
    additionalAddressDetails: string;
    formattedAddress: string;
    deliveryAddress: DetailedAddress | null;
  } {
    return {
      selectedSample: [],
      addressLine2: '',
      additionalAddressDetails: '',
      formattedAddress: '',
      deliveryAddress: null,
    };
  },
  computed: {
    signInComplete(): boolean {
      return this.$store.state.signInComplete;
    },
    samples(): Sample[] {      
      return getOrganizationMembershipBasedFilteredArrayForCurrUser(this.$store.state.samples) as Sample[];
    },
  },
  watch: {
    async signInComplete(val) {
      if (val) {
        await getSamples();
      }
    },
  },
  async mounted() {
    await getSamples();
    const loader = new Loader({
      apiKey: firebaseCredentials.apiKey,
      version: 'weekly',
    });
    loader.load();
  },
  methods: {
    submit() {
      if (!this.deliveryAddress) {
        return;
      }
      this.deliveryAddress.addressLine2 = this.addressLine2;
      const newDoc = doc(collection(currFirestore, 'sampleOrders'));
      setDoc(newDoc, {
        docId: newDoc.id,
        userId: this.$store.state.currUser.docId,
        email: this.$store.state.currUser.email,
        userName: this.$store.state.currUser.displayName,
        companyName: this.$store.state.currUser.companyName,
        phone: this.$store.state.currUser.phone,
        orderItems: this.selectedSample,
        deliveryAddress: this.deliveryAddress,
        dateCreated: Timestamp.fromMillis(Date.now()),
        dateUpdated: Timestamp.fromMillis(Date.now()),
      })
        .then(() => {
          showSuccess('Order submitted successfully');
          this.selectedSample = [];
        })
        .catch((err) => {
          showError('Could not submit order', err);
        });
    },
    getLocationAddress(addressData: any, place: any): DetailedAddress {
      return {
        formattedAddress: place.formatted_address || '',
        latitude: addressData.latitude || null,
        longitude: addressData.longitude || null,
        googlePlaceId: place.place_id || '',
        utcOffset: place.utc_offset_minutes || '',
        addressLine2: this.addressLine2 || '',
        additionalAddressDetails: this.additionalAddressDetails || '',
        address: {
          administrativeAreaLevel1: addressData.administrative_area_level_1 || '',
          administrativeAreaLevel2: addressData.administrative_area_level_2 || '',
          administrativeAreaLevel3: addressData.administrative_area_level_3 || '',
          administrativeAreaLevel4: addressData.administrative_area_level_4 || '',
          administrativeAreaLevel5: addressData.administrative_area_level_5 || '',
          streetAddress: addressData.street_address || '',
          intersection: addressData.intersection || '',
          political: addressData.political || '',
          country: addressData.country || '',
          colloquialArea: addressData.colloquial_area || '',
          locality: addressData.locality || '',
          sublocality: addressData.sublocality || '',
          sublocalityLevel1: addressData.sublocality_level_1 || '',
          sublocalityLevel2: addressData.sublocality_level_2 || '',
          sublocalityLevel3: addressData.sublocality_level_3 || '',
          sublocalityLevel4: addressData.sublocality_level_4 || '',
          sublocalityLevel5: addressData.sublocality_level_5 || '',
          neighborhood: addressData.neighborhood || '',
          postalCode: addressData.postal_code || '',
          route: addressData.route || '',
          streetNumber: addressData.street_number || '',
          premise: addressData.premise || '',
          subpremise: addressData.subpremise || '',
          plusCode: addressData.plus_code || '',
          floor: addressData.floor || '',
        },
      };
    },
    onGetAddressData(addressData: any, place: any) {
      this.deliveryAddress = this.getLocationAddress(addressData, place);
    },
    onGoogleAutocompleteError(error: any) {
      showError(error?.message);
    },
  },
});
