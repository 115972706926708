









import { firebaseCredentials } from '@/util';
import Vue from 'vue';
import VtqContinuingEducation from '@/components/VtqContinuingEducation.vue';
import { Loader } from '@googlemaps/js-api-loader';
import { getContinuingEducation } from '@/resourceUtils';

export default Vue.extend({
  components: {
    VtqContinuingEducation,
  },
  computed: {
    signInComplete(): boolean {
      return this.$store.state.signInComplete;
    },
    continuingEducation(): ContinuingEducation[] {
      return this.$store.state.continuingEducation;
    },
  },
  watch: {
    async signInComplete(val) {
      if (val) {
        await getContinuingEducation();
      }
    },
  },
  async mounted() {
    await getContinuingEducation();
    const loader = new Loader({
      apiKey: firebaseCredentials.apiKey,
      version: 'weekly',
    });
    loader.load();
  },
});
