import * as Util from '@/util';
import { Timestamp } from 'firebase/firestore';
import { FullMetadata } from 'firebase/storage';
import { eOrganizationTypes, eThemes, eUserRoles } from './enums';

// Does this pollute the global namespace? Sure, but I like the pros over the cons.
declare global {
  interface String {
    supplant(o: string[]): string;
  }

  type TableHeaders = { text: string; width?: string; value?: string; sortable?: boolean; align?: string; filterable?: boolean }[];
  type DownloadUrlMap = { [path: string]: string };
  type DownloadMetadataMap = { [path: string]: FullMetadata };

  interface TextValue {
    text: string;
    value: any;
  }

  interface ClientSideOrg {
    docId: string;
    name: string;
  }

  interface TerritoryManager {
    docId: string;
    fullName: string;
    focus: string;
    zip: string;
    phone: string;
    email: string;
    visible: boolean;
    formattedAddress: string;
    placeId: string;
    latLng: number[];
    distance?: number;
    dateCreated?: Timestamp;
    dateUpdated?: Timestamp;
  }

  interface PlatformUser {
    docId: string;
    role: eUserRoles;
    displayName: string;
    companyName: string;
    phone: string;
    email: string;
    marketingEmailNotificationsEnabled: boolean;
    marketingSmsNotificationsEnabled: boolean;
    importantEmailNotificationsEnabled: boolean;
    importantSmsNotificationsEnabled: boolean;
    appTheme: eThemes;
    webPushTokens: string[];
    adminSelectedOrganizationEntities: EntityReference[];
    adminSelectedOrganizations: ClientSideOrg[];
    userSelectedOrganizations: ClientSideOrg[];
    dateCreated?: Timestamp;
    dateUpdated?: Timestamp;
  }

  interface Organization {
    docId: string;
    name: string;
    type: eOrganizationTypes;
    hiddenLiterature: EntityReference[];
    hiddenSamples: EntityReference[];
    hiddenPromotions: EntityReference[];
    hiddenProducts: EntityReference[];
    featuredProducts: EntityReference[];
    featuredPromotions: EntityReference[];
    selfEntityReference: EntityReference;
    dateCreated: Timestamp;
    dateUpdated: Timestamp;
  }

  interface Product {
    docId: string;
    name: string;
    price: number; // Interger in cents.
    description: string; // Markdown.
    ingredients: string; // Html.
    whenToUse: string; // Html.
    howToUse: string; // Html.
    importantSafetyInformation: string[]; // File urls.
    productInsertFiles: string[]; // File urls.
    files: string[]; // File urls.
    images: string[]; // File urls.
    vimeoVideoUrls: string[]; // Vimeo urls.
    logo: string; // File url.
    tags: string[];
    dateCreated: Timestamp;
    dateUpdated: Timestamp;
    visible: boolean;
    isHiddenViaOrg: boolean;
    featured: boolean;
    isPromoted: boolean;
    relatedProducts: EntityReference[];
    relatedProductIds: string[];
  }

  interface Promotion {
    docId: string;
    name: string;
    details: string; // Markdown.
    distributors: string; // Markdown.
    terms: string; // Markdown.
    whoQualifies: string; // Markdown.
    files: string[]; // File urls.
    image: string; // File urls.
    tags: string[];
    dateCreated: any;
    dateUpdated: any;
    visible: boolean;
    featured: boolean;
    isHiddenViaOrg: boolean;
    isPromoted: boolean;
    relatedProducts: EntityReference[];
    relatedProductIds: string[];
  }

  interface BlogPost {
    docId: string;
    title: string;
    author: string;
    blurb: string; // Markdown.
    contentReference: EntityReference; // Markdown.
    image: string;
    goLiveDate: any;
    utcOffset?: number;
    vimeoVideoUrls: string[]; // Vimeo urls.
    files: string[]; // File urls.
    tags: string[];
    dateCreated: any;
    dateUpdated: any;
    visible: boolean;
    isHiddenViaOrg: boolean;
    featured: boolean;
    isPromoted: boolean;
    relatedProducts: EntityReference[];
    relatedProductIds: string[];
  }

  interface Literature {
    docId: string;
    name: string;
    description: string; // Markdown.
    files: string[]; // File urls.
    image: string; // File urls.
    tags: string[];
    dateCreated: any;
    dateUpdated: any;
    visible: boolean;
    isHiddenViaOrg: boolean;
    featured: boolean;
    isPromoted: boolean;
    relatedProducts: EntityReference[];
    relatedProductIds: string[];
  }

  interface Sample {
    docId: string;
    name: string;
    description: string; // Markdown.
    files: string[]; // File urls.
    image: string; // File urls.
    tags: string[];
    dateCreated: any;
    dateUpdated: any;
    visible: boolean;
    isHiddenViaOrg: boolean;
    featured: boolean;
    isPromoted: boolean;
    relatedProducts: EntityReference[];
    relatedProductIds: string[];
  }

  interface GoogleAddress {
    administrativeAreaLevel1: string;
    administrativeAreaLevel2: string;
    administrativeAreaLevel3: string;
    administrativeAreaLevel4: string;
    administrativeAreaLevel5: string;
    streetAddress: string;
    intersection: string;
    political: string;
    country: string;
    colloquialArea: string;
    locality: string;
    sublocality: string;
    sublocalityLevel1: string;
    sublocalityLevel2: string;
    sublocalityLevel3: string;
    sublocalityLevel4: string;
    sublocalityLevel5: string;
    neighborhood: string;
    postalCode: string;
    route: string;
    streetNumber: string;
    premise: string;
    subpremise: string;
    plusCode: string;
    floor: string;
  }

  export interface DetailedAddress {
    formattedAddress: string;
    address: GoogleAddress | null;
    addressLine2: string | null;
    additionalAddressDetails: string;
    latitude: number | null;
    longitude: number | null;
    googlePlaceId: string;
    utcOffset: number;
  }

  interface ContinuingEducation {
    docId: string;
    name: string;
    description: string; // Markdown.
    files: string[]; // File urls.
    image: string; // File urls.
    tags: string[];
    dateCreated: any;
    dateUpdated: any;
    visible: boolean;
  }

  interface ClinicalSolution {
    docId: string;
    name: string;
    description: string; // Markdown.
    files: string[]; // File urls.
    image: string; // File urls.
    tags: string[];
    dateCreated: any;
    dateUpdated: any;
    visible: boolean;
  }

  interface Content {
    content: string; // Markdown.
  }

  interface EntityReference {
    /**
     * Id of the entity
     */
    readonly id: string;
    /**
     * A string representing the path of the referenced document (relative
     * to the root of the database).
     */
    readonly path: string;
  }

  interface BeforeInstallPromptEvent extends Event {
    /**
     * Returns an array of DOMString items containing the platforms on which the event was dispatched.
     * This is provided for user agents that want to present a choice of versions to the user such as,
     * for example, "web" or "play" which would allow the user to chose between a web version or
     * an Android version.
     */
    readonly platforms: Array<string>;

    /**
     * Returns a Promise that resolves to a DOMString containing either "accepted" or "dismissed".
     */
    readonly userChoice: Promise<{
      outcome: 'accepted' | 'dismissed';
      platform: string;
    }>;

    /**
     * Allows a developer to show the install prompt at a time of their own choosing.
     * This method returns a Promise.
     */
    prompt(): Promise<void>;
  }
}

Util.initApp();
