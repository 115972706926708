




































































































































































import Vue from 'vue';
import { isMobileCheck } from '@/util';
import { resourceCollectionMixin } from '@/mixins';

export default Vue.extend({
  mixins: [resourceCollectionMixin],
  data(): {
    headers: TableHeaders;
    currContent: string;
    productCarouselIndex: number;
  } {
    const headers: TableHeaders = [
      {
        text: 'Name',
        value: 'name',
        sortable: true,
        width: '25%',
      },
      {
        text: 'Categories',
        value: 'tags',
        sortable: true,
        width: '30%',
      },
    ];
    if (!isMobileCheck()) {
      headers.push({
        text: 'Description',
        value: 'description',
        sortable: false,
        width: '45%',
        filterable: false,
      });
    }
    return {
      headers,
      currContent: '',
      productCarouselIndex: 0,
    };
  },
  computed: {
    filteredProducts(): Product[] {
      const self: any = this;
      const filteredProducts = self.items.filter((product: Product) => {
        let passesTagTest = true;
        let passesSearchText = true;
        if (self.selectedTags && self.selectedTags.length) {
          // If this product contains a selected tag, add it to the list.
          passesTagTest = self.selectedTags.some((tag: string) => {
            return product.tags?.includes(tag);
          });
        }
        if (self.search) {
          passesSearchText = product.name.toLowerCase().includes(self.search.toLowerCase());
        }
        return passesTagTest && passesSearchText;
      });
      return filteredProducts;
    },
    filteredProductsInPage(): Product[] {
      const startIndex = (this.$data.page - 1) * this.$data.itemsPerPage;
      return this.filteredProducts.slice(startIndex, startIndex + this.$data.itemsPerPage);
    },
  },
  mounted() {
    this.$data.pageCount = Math.ceil(this.filteredProducts.length / this.$data.itemsPerPage);
  },
  watch: {
    filteredProducts(val) {
      this.$data.pageCount = Math.ceil(val.length / this.$data.itemsPerPage);
    },
  },
  methods: {
    formatPrice(price: number) {
      if (!price) {
        return '-';
      }
      return price.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
      });
    },
  },
});
