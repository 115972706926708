


















import Vue from 'vue';
import { getBlogPosts, getProducts, getPromotions } from '@/resourceUtils';
import VtqBlogPosts from '@/components/VtqBlogPosts.vue';
import VtqProducts from '@/components/VtqProducts.vue';
import VtqPromotions from '@/components/VtqPromotions.vue';
import { getOrganizationMembershipBasedFilteredArrayForCurrUser, isMobileCheck } from '@/util';
import { eUserRoles } from '@/enums';

export default Vue.extend({
  components: {
    VtqBlogPosts,
    VtqProducts,
    VtqPromotions,
  },
  data(): {
    blogPostCarouselIndex: number;
    showBlogPostDialog: boolean;
    blogPosts: BlogPost[];
    products: Product[];
    promotions: Promotion[];
    currContent: string;
    eUserRoles: typeof eUserRoles;
  } {
    return {
      blogPostCarouselIndex: 0,
      showBlogPostDialog: false,
      blogPosts: [],
      products: [],
      promotions: [],
      currContent: '',
      eUserRoles,
    };
  },
  computed: {
    isApplicant(): boolean {
      return this.$store.state.currUser.role === eUserRoles.applicant && this.$store.state.signInComplete;
    },
    signInComplete(): boolean {
      return this.$store.state.signInComplete;
    },
    downloadUrlMap(): DownloadUrlMap {
      return this.$store.state.downloadUrlMap;
    },
    downloadMetadataMap(): DownloadMetadataMap {
      return this.$store.state.downloadMetadataMap;
    },
  },
  watch: {
    signInComplete(val) {
      if (val) {
        this.onLoad();
      }
    },
  },
  mounted() {
    this.onLoad();
  },
  methods: {
    onLoad() {
      getBlogPosts().then((blogPosts) => {
        this.blogPosts = blogPosts.filter((b) => b.featured);
      });
      getProducts().then((products) => {
        this.products = (getOrganizationMembershipBasedFilteredArrayForCurrUser(products) as Product[]).filter((p) => p.featured).slice(0, isMobileCheck() ? 4 : 3);
      });
      getPromotions().then((promotions) => {
        this.promotions = (getOrganizationMembershipBasedFilteredArrayForCurrUser(promotions) as Promotion[]).filter((p) => p.featured).slice(0, isMobileCheck() ? 4 : 3);
      });
    },
  },
});
