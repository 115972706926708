





















































































































































































































import Vue from 'vue';
import { eThemes, eUserRoles } from '@/enums';
import { saveSettings, showError, showNotice, signOutAccount } from './util';

export default Vue.extend({
  name: 'App',
  data(): {
    eThemes: typeof eThemes;
    APP_VERSION?: string;
    isMounted: boolean;
    showSnackbar: boolean;
    drawer: boolean;
    showPrivacyPolicy: boolean;
    showTermsOfService: boolean;
    showConfirmationDialog: boolean;
    confirmationDialogHasACancelOption: boolean;
    snackBarMsg: string;
    confirmationDialogMsg: string;
    eUserRoles: typeof eUserRoles;
    currentConfirmationDialogAffirmativeFunc: () => undefined;
    isAlreadyInstalled: boolean;
  } {
    return {
      eThemes,
      APP_VERSION: process.env.APP_VERSION || '',
      isMounted: false,
      showSnackbar: false,
      drawer: false,
      showPrivacyPolicy: false,
      showTermsOfService: false,
      showConfirmationDialog: false,
      confirmationDialogHasACancelOption: false,
      snackBarMsg: '',
      confirmationDialogMsg: '',
      eUserRoles,
      currentConfirmationDialogAffirmativeFunc: () => undefined,
      isAlreadyInstalled: window.matchMedia('(display-mode: standalone)').matches,
    };
  },
  computed: {
    isApplicant(): boolean {
      return this.currUser.role === eUserRoles.applicant && this.$store.state.signInComplete;
    },
    isLoggedIn(): boolean {
      return this.$store.state.isLoggedIn;
    },
    initialLoadIsHappening(): boolean {
      return this.$store.state.initialLoadIsHappening;
    },
    currUser(): PlatformUser {
      return this.$store.state.currUser;
    },
    isLoading(): boolean {
      return this.initialLoadIsHappening || this.$store.state.isLoading;
    },
    currTheme: {
      get(): eThemes {
        if (this.$store.state.appTheme === eThemes.dark) {
          (this as any).$vuetify.theme.dark = true;
        } else {
          (this as any).$vuetify.theme.dark = false;
        }
        return this.$store.state.appTheme;
      },
      set(appTheme: eThemes) {
        this.$store.commit('appTheme', appTheme);
        saveSettings({ appTheme });
      },
    },
  },
  mounted() {
    this.isMounted = true;
  },
  methods: {
    signOutAccount() {
      signOutAccount();
    },
    isOnPage(path: string) {
      const currPath = this.$router.mode === 'hash' ? window.location.hash : window.location.pathname;
      return currPath === path;
    },
    showSnackbarFunc(msg: string) {
      if (msg.length > 160) {
        this.showSnackbar = false;
        showNotice(this.snackBarMsg);
      } else {
        this.showSnackbar = true;
        this.snackBarMsg = `<div>${msg}</div>`;
      }
    },
    showConfirmationDialogFunc(msg: string, onConfirmFunc: any, confirmationDialogHasACancelOption = true) {
      this.showConfirmationDialog = true;
      this.confirmationDialogMsg = msg;
      this.confirmationDialogHasACancelOption = confirmationDialogHasACancelOption;
      this.currentConfirmationDialogAffirmativeFunc = onConfirmFunc;
    },
    hideConfirmationDialog() {
      this.showConfirmationDialog = false;
      this.currentConfirmationDialogAffirmativeFunc = () => undefined;
    },
    onConfirmationYesClick() {
      if (this.currentConfirmationDialogAffirmativeFunc) {
        this.currentConfirmationDialogAffirmativeFunc();
      } else {
        showError('No confirmation function provided for the dialog.');
      }
      this.hideConfirmationDialog();
    },
  },
});
