









import Vue from 'vue';
import { getPromotions } from '@/resourceUtils';
import VtqPromotions from '@/components/VtqPromotions.vue';
import { getOrganizationMembershipBasedFilteredArrayForCurrUser } from '@/util';

export default Vue.extend({
  components: {
    VtqPromotions,
  },
  computed: {
    signInComplete(): boolean {
      return this.$store.state.signInComplete;
    },
    promotions(): Promotion[] {
      return getOrganizationMembershipBasedFilteredArrayForCurrUser(this.$store.state.promotions) as Promotion[];
    },
  },
  watch: {
    async signInComplete(val) {
      if (val) {
        await getPromotions();
      }
    },
  },
  async mounted() {
    await getPromotions();
  },
});
