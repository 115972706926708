















import Vue from 'vue';
export default Vue.extend({
  data(): {
    showLoadingSpinner: boolean;
  } {
    return {
      showLoadingSpinner: true,
    };
  },
  computed: {    
    isLoading(): boolean {
      return this.$store.state.isLoading;
    },
  },
  mounted() {
    setTimeout(() => {
      this.showLoadingSpinner = false;
    }, 5000);
  },
});
