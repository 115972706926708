











import Vue from 'vue';

export default Vue.extend({
  props: {
    text: {
      type: String,
      default: '',
    },
    path: {
      type: String,
      default: '',
    },
    large: {
      type: Boolean,
      default: false,
    },
    center: {
      type: Boolean,
      default: false,
    },
  },
});
