import Vue from 'vue';
import VueRouter from 'vue-router';
import Dashboard from '@/views/Dashboard.vue';
import { fbAnalytics, hideLoading } from './util';
import { store } from './store';
import { logEvent } from 'firebase/analytics';

Vue.use(VueRouter);
const routes = [
  {
    path: '*',
    name: 'Dashboard',
    component: Dashboard,
  },
  {
    path: '/News',
    name: 'News',
    component: () => import(/* webpackChunkName: "News" */ '@/views/News.vue'),
  },
  {
    path: '/Products',
    name: 'Products',
    component: () => import(/* webpackChunkName: "Products" */ '@/views/Products.vue'),
  },
  {
    path: '/Promotions',
    name: 'Promotions',
    component: () => import(/* webpackChunkName: "Promotions" */ '@/views/Promotions.vue'),
  },
  {
    path: '/Literature',
    name: 'Literature',
    component: () => import(/* webpackChunkName: "Literature" */ '@/views/Literature.vue'),
  },
  {
    path: '/Samples',
    name: 'Samples',
    component: () => import(/* webpackChunkName: "Sample" */ '@/views/Samples.vue'),
  },  
  {
    path: '/ContinuingEducation',
    name: 'ContinuingEducation',
    component: () => import(/* webpackChunkName: "ContinuingEducation" */ '@/views/ContinuingEducation.vue'),
  },
  {
    path: '/Solutions',
    name: 'Solutions',
    component: () => import(/* webpackChunkName: "ClinicalSolutions" */ '@/views/ClinicalSolutions.vue'),
  },
  {
    path: '/Contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "Contact" */ '@/views/Contact.vue'),
  },
  {
    path: '/About/',
    name: 'About',
    component: () => import(/* webpackChunkName: "About" */ '@/views/About.vue'),
  },
  {
    path: '/FAQ',
    name: 'FAQ',
    component: () => import(/* webpackChunkName: "FAQ" */ '@/views/FAQ.vue'),
  },
  {
    path: '/GetApp',
    name: 'GetApp',
    component: () => import(/* webpackChunkName: "GetApp" */ '@/views/GetApp.vue'),
  },
  {
    path: '/Settings',
    name: 'Settings',
    component: () => import(/* webpackChunkName: "Settings" */ '@/views/Settings.vue'),
  },
  {
    path: '/TermsOfService',
    name: 'TermsOfService',
    component: () => import(/* webpackChunkName: "TermsOfService" */ '@/views/TermsOfService.vue'),
  },
  {
    path: '/PrivacyPolicy',
    name: 'PrivacyPolicy',
    component: () => import(/* webpackChunkName: "PrivacyPolicy" */ '@/views/PrivacyPolicy.vue'),
  },
  {
    path: '/Login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "Login" */ '@/views/Login.vue'),
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name && !['PrivacyPolicy', 'TermsOfService', 'Login'].includes(to.name) && !store.state.isLoggedIn && !store.state.initialLoadIsHappening) {
    next({ name: 'Login' });
  }
  // Don't allow going back to the Login screen.
  if (!(to.name && ['Login'].includes(to.name) && store.state.isLoggedIn)) {
    next();
  }
});

router.afterEach((to) => {
  const options: any = {
    page_location: to.fullPath,
    page_title: to.name,
  };
  logEvent(fbAnalytics, 'page_view', options);
  hideLoading();
});

export default router;
