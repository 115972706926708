import { getDocs } from '@firebase/firestore';
import { collection, doc, getDoc, query, where } from 'firebase/firestore';
import { eUserRoles } from './enums';
import { store, storeEventBus } from './store';
import { currFirestore, getDownloadUrlsAndCache, hideLoading, showError, showLoading } from './util';

export function getBlogPosts(): Promise<BlogPost[]> {
  return new Promise((resolve, reject) => {
    if (!store.state.signInComplete) {
      resolve([]);
      return;
    }
    const blogPostsInStore = store.state.blogPosts;
    if (blogPostsInStore.length) {
      resolve(blogPostsInStore);
      storeEventBus.$emit('resourcesUpdated');
      return;
    }
    showLoading();  
    getDocs(query(collection(currFirestore, 'blogPosts'), where('visible', '==', true)))
      .then((querySnap) => {
        const newBlogPosts: BlogPost[] = [];
        querySnap.forEach((docSnap) => {
          const blogPost = docSnap.data() as BlogPost;
          newBlogPosts.push({
            ...blogPost,
            docId: docSnap.id,
            relatedProductIds: blogPost.relatedProducts?.map((r) => r.id) || [],
          });
          getDownloadUrlsAndCache([blogPost.image]);
          getDownloadUrlsAndCache(blogPost.files);
        });

        hideLoading();
        newBlogPosts.sort((a: BlogPost, b: BlogPost) => {
          return b.goLiveDate.toMillis() - a.goLiveDate.toMillis();
        });
        store.commit('blogPosts', newBlogPosts);
        resolve(newBlogPosts);
      })
      .catch((err) => {
        hideLoading();
        showError(`Something went wrong.`, err);
        reject(err);
      })
      .finally(() => {
        storeEventBus.$emit('resourcesUpdated');
      });    
  });
}

export function getPromotions(): Promise<Promotion[]> {
  return new Promise((resolve, reject) => {    
    if (!store.state.signInComplete || store.state.currUser.role === eUserRoles.applicant) {      
      resolve([]);
      return;
    }    
    const promotionsInStore = store.state.promotions;
    if (promotionsInStore.length) {
      resolve(promotionsInStore);      
      storeEventBus.$emit('resourcesUpdated');
      return;
    }    
    showLoading();
    getDocs(query(collection(currFirestore, 'promotions'), where('visible', '==', true)))
      .then((querySnap) => {
        const newPromotions: Promotion[] = [];

        querySnap.forEach((docSnap) => {
          const promotion = docSnap.data() as Promotion;
          newPromotions.push({
            ...promotion,
            docId: docSnap.id,
            relatedProductIds: promotion.relatedProducts?.map((r) => r.id) || [],
          });
          getDownloadUrlsAndCache([promotion.image]);
          getDownloadUrlsAndCache(promotion.files);
        });

        hideLoading();
        newPromotions.sort((a: Promotion, b: Promotion) => {
          return b.dateCreated.toMillis() - a.dateCreated.toMillis();
        });
        store.commit('promotions', newPromotions);
        resolve(newPromotions);
      })
      .catch((err) => {
        hideLoading();
        showError(`Something went wrong.`, err);
        reject(err);
      })
      .finally(() => {
        storeEventBus.$emit('resourcesUpdated');
      });
  });
}

export function getProducts(): Promise<Product[]> {
  return new Promise((resolve, reject) => {
    if (!store.state.signInComplete || store.state.currUser.role === eUserRoles.applicant) {
      resolve([]);
      return;
    }
    const productsInStore = store.state.products;    
    if (productsInStore.length) {
      resolve(productsInStore);
      storeEventBus.$emit('resourcesUpdated');
      return;
    }
    showLoading();
    getDocs(query(collection(currFirestore, 'products'), where('visible', '==', true)))
      .then((querySnap) => {
        const newProducts: Product[] = [];
        querySnap.forEach((docSnap) => {
          const product = docSnap.data() as Product;
          newProducts.push({
            ...product,
            docId: docSnap.id,
            relatedProductIds: product.relatedProducts?.map((r) => r.id) || [],
          });
          getDownloadUrlsAndCache(product.images);
          getDownloadUrlsAndCache([product.logo]);
          getDownloadUrlsAndCache(product.files);
          getDownloadUrlsAndCache(product.importantSafetyInformation);
          getDownloadUrlsAndCache(product.productInsertFiles);
        });
        hideLoading();
        newProducts.sort((a: Product, b: Product) => {
          return b.dateCreated.toMillis() - a.dateCreated.toMillis();
        });        
        store.commit('products', newProducts);
        resolve(newProducts);
      })
      .catch((err) => {
        hideLoading();
        showError(`Something went wrong.`, err);
        reject(err);
      })
      .finally(() => {
        storeEventBus.$emit('resourcesUpdated');
      });
  });
}

export function getLiterature(): Promise<Literature[]> {
  return new Promise((resolve, reject) => {
    if (!store.state.signInComplete || store.state.currUser.role === eUserRoles.applicant) {
      resolve([]);
      return;
    }
    const literatureInStore = store.state.literature;
    if (literatureInStore.length) {
      resolve(literatureInStore);
      storeEventBus.$emit('resourcesUpdated');
      return;
    }
    showLoading();
    getDocs(query(collection(currFirestore, 'literature'), where('visible', '==', true)))
      .then((querySnap) => {
        const newLiterature: Literature[] = [];
        querySnap.forEach((docSnap) => {
          const literature = docSnap.data() as Literature;
          newLiterature.push({
            ...literature,
            docId: docSnap.id,
            relatedProductIds: literature.relatedProducts?.map((r) => r.id) || [],
          });
          getDownloadUrlsAndCache([literature.image]);
          getDownloadUrlsAndCache(literature.files);
        });
        hideLoading();
        newLiterature.sort((a: Literature, b: Literature) => {
          return b.dateCreated.toMillis() - a.dateCreated.toMillis();
        });
        store.commit('literature', newLiterature);
        resolve(newLiterature);
      })
      .catch((err) => {
        hideLoading();
        showError(`Something went wrong.`, err);
        reject(err);
      })
      .finally(() => {
        storeEventBus.$emit('resourcesUpdated');
      });
  });
}

export function getSamples(): Promise<Sample[]> {
  return new Promise((resolve, reject) => {
    if (!store.state.signInComplete || store.state.currUser.role === eUserRoles.applicant) {
      resolve([]);
      return;
    }
    const samplesInStore = store.state.samples;
    if (samplesInStore.length) {
      resolve(samplesInStore);
      storeEventBus.$emit('resourcesUpdated');
      return;
    }
    showLoading();
    getDocs(query(collection(currFirestore, 'samples'), where('visible', '==', true)))
      .then((querySnap) => {
        const newSamples: Sample[] = [];
        querySnap.forEach((docSnap) => {
          const sample = docSnap.data() as Sample;
          newSamples.push({
            ...sample,
            docId: docSnap.id,
            relatedProductIds: sample.relatedProducts?.map((r) => r.id) || [],
          });
          getDownloadUrlsAndCache([sample.image]);
          getDownloadUrlsAndCache(sample.files);
        });
        hideLoading();
        newSamples.sort((a: Sample, b: Sample) => {
          return b.dateCreated.toMillis() - a.dateCreated.toMillis();
        });
        store.commit('samples', newSamples);
        resolve(newSamples);
      })
      .catch((err) => {
        hideLoading();
        showError(`Something went wrong.`, err);
        reject(err);
      })
      .finally(() => {
        storeEventBus.$emit('resourcesUpdated');
      });
  });
}

export function getContinuingEducation(): Promise<ContinuingEducation[]> {
  return new Promise((resolve, reject) => {
    if (!store.state.signInComplete || store.state.currUser.role === eUserRoles.applicant) {
      resolve([]);
      return;
    }
    const continuingEducationInStore = store.state.continuingEducation;
    if (continuingEducationInStore.length) {
      resolve(continuingEducationInStore);
      storeEventBus.$emit('resourcesUpdated');
      return;
    }
    showLoading();
    getDocs(query(collection(currFirestore, 'continuingEducation'), where('visible', '==', true)))
      .then((querySnap) => {
        const newcontinuingEducation: ContinuingEducation[] = [];
        querySnap.forEach((docSnap) => {
          const continuingEducation = docSnap.data() as ContinuingEducation;
          newcontinuingEducation.push({
            ...continuingEducation,
            docId: docSnap.id,
          });
          getDownloadUrlsAndCache([continuingEducation.image]);
          getDownloadUrlsAndCache(continuingEducation.files);
        });
        hideLoading();
        newcontinuingEducation.sort((a: ContinuingEducation, b: ContinuingEducation) => {
          return b.dateCreated.toMillis() - a.dateCreated.toMillis();
        });
        store.commit('continuingEducation', newcontinuingEducation);
        resolve(newcontinuingEducation);
      })
      .catch((err) => {
        hideLoading();
        showError(`Something went wrong.`, err);
        reject(err);
      })
      .finally(() => {
        storeEventBus.$emit('resourcesUpdated');
      });
  });
}

export function getClinicalSolutions(): Promise<ClinicalSolution[]> {
  return new Promise((resolve, reject) => {
    if (!store.state.signInComplete || store.state.currUser.role === eUserRoles.applicant) {
      resolve([]);
      return;
    }
    const clinicalSolutionsInStore = store.state.clinicalSolutions;
    if (clinicalSolutionsInStore.length) {
      resolve(clinicalSolutionsInStore);
      storeEventBus.$emit('resourcesUpdated');
      return;
    }
    showLoading();
    getDocs(query(collection(currFirestore, 'clinicalSolutions'), where('visible', '==', true)))
      .then((querySnap) => {
        const newclinicalSolutions: ClinicalSolution[] = [];
        querySnap.forEach((docSnap) => {
          const clinicalSolutions = docSnap.data() as ClinicalSolution;
          newclinicalSolutions.push({
            ...clinicalSolutions,
            docId: docSnap.id,
          });
          getDownloadUrlsAndCache([clinicalSolutions.image]);
          getDownloadUrlsAndCache(clinicalSolutions.files);
        });
        hideLoading();
        newclinicalSolutions.sort((a: ClinicalSolution, b: ClinicalSolution) => {
          return b.dateCreated.toMillis() - a.dateCreated.toMillis();
        });
        store.commit('clinicalSolutions', newclinicalSolutions);
        resolve(newclinicalSolutions);
      })
      .catch((err) => {
        hideLoading();
        showError(`Something went wrong.`, err);
        reject(err);
      })
      .finally(() => {
        storeEventBus.$emit('resourcesUpdated');
      });
  });
}

export class EntityReference {
  /**
   * ID of the entity
   */
  readonly id: string;
  /**
   * A string representing the path of the referenced document (relative
   * to the root of the database).
   */
  readonly path: string;

  constructor(id: string, path: string) {
    this.id = id;
    this.path = path;
  }

  get pathWithId() {
    return `${this.path}/${this.id}`;
  }
}

export function getOrganizations(): Promise<Organization[]> {
  return new Promise((resolve, reject) => {
    const organizationsInStore = store.state.organizations;
    if (organizationsInStore.length) {
      resolve(organizationsInStore);
      storeEventBus.$emit('resourcesUpdated');
      return;
    }
    showLoading();
    getDocs(collection(currFirestore, 'organizations'))
      .then((querySnap) => {
        const newOrganizations: Organization[] = [];
        querySnap.forEach((docSnap) => {
          const organization = docSnap.data() as Organization;
          newOrganizations.push({
            ...organization,
            docId: docSnap.id,
          });
        });
        hideLoading();
        newOrganizations.sort((a: Organization, b: Organization) => {
          return b.dateCreated.toMillis() - a.dateCreated.toMillis();
        });
        store.commit('organizations', newOrganizations);        
        resolve(newOrganizations);
      })
      .catch((err) => {
        hideLoading();
        showError(`Something went wrong.`, err);
        reject(err);
      })
      .finally(() => {
        storeEventBus.$emit('resourcesUpdated');
      });
  });
}

export const getProduct = (itemId: string): Product | undefined => {
  return store.state.products.find((i) => i.docId === itemId);
};

export const getPromotion = (itemId: string): Promotion | undefined => {
  return store.state.promotions.find((i) => i.docId === itemId);
};

export const getBlogPost = (itemId: string): BlogPost | undefined => {
  return store.state.blogPosts.find((i) => i.docId === itemId);
};

export const getLiteratureItem = (itemId: string): Literature | undefined => {
  return store.state.literature.find((i) => i.docId === itemId);
};

export const getSample = (itemId: string): Sample | undefined => {
  return store.state.samples.find((i) => i.docId === itemId);
};

export function getContactPageContent(): Promise<any> {
  return new Promise((resolve, reject) => {
    if (!store.state.signInComplete) {
      resolve(null);
      return;
    }
    const contactPageContent = store.state.contactPageContent;
    if (contactPageContent.length) {
      resolve(contactPageContent);
      return;
    }
    showLoading();
    getDoc(doc(currFirestore, 'contactContent', 'contactContent'))
      .then((docSnap) => {
        const contactPageContent = docSnap.data() as any as string;
        store.commit('contactPageContent', contactPageContent);
        resolve(contactPageContent);
      })
      .catch((err) => {
        showError('Could not get contact page content', err);
        reject(null);
      })
      .finally(() => {
        hideLoading();
      });
  });
}

export function getAboutPageContent(): Promise<any> {
  return new Promise((resolve, reject) => {
    if (!store.state.signInComplete) {
      resolve(null);
      return;
    }
    const aboutPageContent = store.state.aboutPageContent;
    if (aboutPageContent.length) {
      resolve(aboutPageContent);
      return;
    }
    showLoading();
    getDoc(doc(currFirestore, 'aboutContent', 'aboutContent'))
      .then((docSnap) => {
        const aboutPageContent = docSnap.data() as any as string;
        store.commit('aboutPageContent', aboutPageContent);
        resolve(aboutPageContent);
      })
      .catch((err) => {
        showError('Could not get about page content', err);
        reject(null);
      })
      .finally(() => {
        hideLoading();
      });
  });
}

export function getFaqPageContent(): Promise<null | { question: string; answer: string }[]> {
  return new Promise((resolve, reject) => {
    if (!store.state.signInComplete) {
      resolve(null);
      return;
    }
    const faqPageContent = store.state.faqPageContent;
    if (faqPageContent.length) {
      resolve(faqPageContent);
      return;
    }
    showLoading();
    getDoc(doc(currFirestore, 'faqContent', 'faqContent'))
      .then((docSnap) => {
        const faqPageContent = docSnap.data() as any;
        store.commit('faqPageContent', faqPageContent);
        resolve(faqPageContent);
      })
      .catch((err) => {
        showError('Could not get faq page content', err);
        reject(null);
      })
      .finally(() => {
        hideLoading();
      });
  });
}

export function getTerritoryManagers(): Promise<TerritoryManager[]> {
  return new Promise((resolve, reject) => {
    if (!store.state.signInComplete) {
      resolve([]);
      return;
    }
    const territoryManagersInStore = store.state.territoryManagers;
    if (territoryManagersInStore.length) {
      resolve(territoryManagersInStore);
      return;
    }
    showLoading();
    getDocs(query(collection(currFirestore, 'territoryManagers'), where('visible', '==', true)))
      .then((querySnap) => {
        const newTerritoryManagers: TerritoryManager[] = [];
        querySnap.forEach((docSnap) => {
          const territoryManager = docSnap.data() as TerritoryManager;
          newTerritoryManagers.push({
            ...territoryManager,
            docId: docSnap.id,
          });
        });
        hideLoading();
        newTerritoryManagers.sort((a: TerritoryManager, b: TerritoryManager) => {
          const aLastName = a.fullName?.split(' ')?.[1] || '';
          const bLastName = b.fullName?.split(' ')?.[1] || '';
          if (aLastName < bLastName) {
            return -1;
          }
          if (aLastName > bLastName) {
            return 1;
          }
          return 0;
        });
        store.commit('territoryManagers', newTerritoryManagers);
        resolve(newTerritoryManagers);
      })
      .catch((err) => {
        hideLoading();
        showError(`Something went wrong.`, err);
        reject([]);
      });
  });
}
